import { gql, useLazyQuery, useQuery } from '@apollo/client';

export const LOAD_ITEMS_DIRECTORY = gql`
  query items(
    $first: Int
    $after: String
    $before: String
    $where: ItemDTOFilterInput
    $order: [ItemDTOSortInput!]
    $last: Int
  ) {
    items(
      first: $first
      after: $after
      before: $before
      where: $where
      order: $order
      last: $last
    ) {
      nodes {
        itemId
        displayId
        itemNameArabic
        itemNameEnglish
        manufacturerName
        imageId
        costPrice
        retailSellPrice
        wholeSellPrice
        hasVATInclSellPrice
        location
        thresholdQuantity
        bin
        shelfNumber
        discountPercentage
        storageSection
        storageType
        # barCode
        onHand
        sellableOnHand
        supplierReserved
        categoryID
        category {
          categoryNameEnglish
          categoryNameArabic
          id
        }
        itemUnitOfMeasure {
          unitId
          nameArabic
          nameEnglish
          barCode
          sellPrice
          costPrice
          isRetail
          piecesPerUnit
          discountPercentage
          discountAmount
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const ADMIN_LOAD_ITEMS_DIRECTORY = gql`
  query items(
    $branchId: String
    $first: Int
    $after: String
    $before: String
    $where: ItemDTOFilterInput
    $order: [ItemDTOSortInput!]
    $last: Int
  ) {
    items(
      branchId: $branchId
      first: $first
      after: $after
      before: $before
      where: $where
      order: $order
      last: $last
    ) {
      nodes {
        itemId
        displayId
        itemNameArabic
        itemNameEnglish
        manufacturerName
        imageId
        costPrice
        retailSellPrice
        wholeSellPrice
        hasVATInclSellPrice
        location
        thresholdQuantity
        bin
        shelfNumber
        discountPercentage
        storageSection
        storageType
        # barCode
        onHand
        sellableOnHand
        supplierReserved
        categoryID
        category {
          categoryNameEnglish
          categoryNameArabic
          id
        }
        itemUnitOfMeasure {
          unitId
          nameArabic
          nameEnglish
          barCode
          sellPrice
          costPrice
          isRetail
          piecesPerUnit
          discountPercentage
          discountAmount
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const ITEM_BY_ID = gql`
  query ($id: Int!) {
    itemById(id: $id) {
      itemId
      displayId
      imageId
      itemNameArabic
      itemNameEnglish
      piecesPerUnit
      manufacturerName
      costPrice
      retailSellPrice
      wholeSellPrice
      retailSellNetPrice
      wholeSellNetPrice
      hasVATInclSellPrice
      location
      thresholdQuantity
      bin
      shelfNumber
      sellableReserved
      storageSection
      storageType
      barCode
      onHand
      sellableOnHand
      supplierReserved
      categoryID
      category {
        categoryNameEnglish
        categoryNameArabic
        id
      }
      itemUnitOfMeasure {
        unitId
        nameArabic
        nameEnglish
        barCode
        netSellPrice
        sellPrice
        costPrice
        isRetail
        piecesPerUnit
        discountPercentage
        discountAmount
        baseUnit
      }
      wholeSellPrice
      itemNameEnglish
      itemNameArabic
      manufacturerName
      piecesPerUnit
      thresholdQuantity
      barCode
      location
      storageType
      bin
      discountPercentage
      discountAmount
      receivableReserved
      deliverableReserved
      supplierId

      supplier {
        id
        nameEnglish
        nameArabic
      }
      tax {
        nameArabic
        nameEnglish
        categoryCode
        id
        rate
        categoryCode
      }
      taxExemptionReasonId
      taxExemptionReason {
        id
        reasonCode
        text
      }
      taxId
    }
  }
`;

export const LOAD_ITEMS_SEARCH = gql`
  query items($where: ItemDTOFilterInput, $branchId: String) {
    items(where: $where, branchId: $branchId) {
      nodes {
        itemId
        itemNameEnglish
        itemNameArabic
      }
    }
  }
`;
export const SEARCH_ITEM = gql`
  query ($where: ItemDTOFilterInput) {
    items(where: $where) {
      nodes {
        itemId
        categoryID
        hasVATInclSellPrice
        costPrice
        retailSellPrice
        units {
          nameEnglish
        }
        wholeSellPrice
        itemNameEnglish
        itemNameArabic
        manufacturerName
        piecesPerUnit
        thresholdQuantity
        barCode
        location
        storageType
        storageSection
        bin
      }
      edges {
        cursor
      }
    }
  }
`;

export const useSearchItem = () => {
  const [searchItem, { error, data, loading, fetchMore }] =
    useLazyQuery(SEARCH_ITEM);
  return {
    error,
    data,
    loading,
    fetchMore,
    searchItem,
  };
};

export const LOAD_EMPLOYEES = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: EmployeeDTOFilterInput
    $order: [EmployeeDTOSortInput!]
  ) {
    employees(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        id
        nameArabic
        nameEnglish
        contact
        iqamaNumber
        iqamaExpiryDate
        designation
        joiningDate
        accomodation
        travelling
        communication
        basicSalary
        totalSalary
        status
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const EMPLOYEE_BY_ID = gql`
  query ($id: Int!) {
    employeeById(id: $id) {
      id
      nameArabic
      nameEnglish
      contact
      iqamaNumber
      iqamaExpiryDate
      designation
      joiningDate
      accomodation
      travelling
      communication
      basicSalary
      totalSalary
      status
      #salaryRecords {
      #id
      #basicSalary
      #month
      #paymentMethod
      #advanceAmount
      #deductionAmount
      #totalSalary
      #}
    }
  }
`;

export const ADJUSTMENT_LOAD = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: ItemAdjustmentDTOFilterInput
    $order: [ItemAdjustmentDTOSortInput!]
  ) {
    itemAdjustments(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        date
        reason
      }
    }
  }
`;

export const AJDUSTMENT_BY_ID = gql`
  query ($id: Int!) {
    itemAdjustmentById(id: $id) {
      id
      date
      reason
      createdBy
      itemDetails {
        itemId
        adjustmentId
        unitId
        costPrice
        quantity
        action
        total
        item {
          itemId
          itemNameEnglish
          itemNameArabic
          sellableOnHand
        }
        unit {
          id
          nameEnglish
          nameArabic
        }
      }
    }
  }
`;

// /* SUPPLIER RESERVATIONS */

export const GET_SUPPLIER_RESERVATIONS = gql`
  query supplierReservations(
    $first: Int
    $after: String
    $before: String
    $where: ItemReservationDTOFilterInput
    $order: [ItemReservationDTOSortInput!]
    $last: Int
  ) {
    supplierReservations(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        id
        name
        quantity
        sold
        unSold
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const SUPPLIER_RESERVATION_DETAIL = gql`
  query supplierReservationDetail($itemId: Int!) {
    supplierReservationDetail(itemId: $itemId) {
      reserverId
      fullName
      invoiceId
      itemId
      reservedQuantity
    }
  }
`;

/* CUSTOMER RESERVATIONS */

export const GET_CUSTOMER_RESERVATIONS = gql`
  query (
    $first: Int
    $after: String
    $before: String
    $where: ItemReservationDTOFilterInput
    $order: [ItemReservationDTOSortInput!]
    $last: Int
  ) {
    customerReservations(
      first: $first
      after: $after
      before: $before
      where: $where
      order: $order
      last: $last
    ) {
      nodes {
        id
        name
        quantity
        sold
        unSold
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const CUSTOMER_RESERVATION_DETAIL = gql`
  query customerReservationDetail($itemId: Int!) {
    customerReservationDetail(itemId: $itemId) {
      fullName
      reserverId
      invoiceId
      itemId
      reservedQuantity
    }
  }
`;

export const GET_PURCHASE_ORDERS = gql`
  query PurchaseOrders(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: PurchaseOrderDTOFilterInput
    $order: [PurchaseOrderDTOSortInput!]
  ) {
    purchaseOrders(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        id
        createdAt
        createdBy
        purchaseOrderStatus
        supplier {
          nameEnglish
          nameArabic
        }
        orderItems {
          item {
            itemNameEnglish
            itemNameArabic
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
      }
    }
  }
`;

export const PURCHASE_ORDER_BY_ID = gql`
  query PurchaseOrderById($id: Int!) {
    purchaseOrderById(id: $id) {
      supplierId
      createdBy
      createdAt
      total
      discountAmount
      netTotal
      totalVAT
      grossTotal
      purchaseOrderStatus
      termsAndConditions
      discountPercentage
      taxId
      purchaseOrderActivityLogs {
        id
        title
        description
        createdBy
        createdAt
      }
      supplier {
        nameEnglish
        nameArabic
      }
      supplierSellMan {
        nameEnglish
        nameArabic
        id
      }
      tax {
        id
        categoryCode
        rate
      }
      orderItems {
        itemId
        price
        quantity
        vATPercentage
        total
        totalVAT
        totalVATIncl
        discountAmount
        taxId
        unit {
          nameEnglish
          nameArabic
          id
        }
        item {
          itemNameEnglish
          itemNameArabic
          bin
          location
          discountPercentage
        }
        tax {
          categoryCode
          rate
        }
      }
    }
  }
`;

// ####################
// Suppliers Type Query #U
// ####################

export const GET_ALL_Suppliers = gql`
  query suppliers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [SupplierDTOSortInput!]
    $where: SupplierDTOFilterInput
  ) {
    suppliers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      nodes {
        id
        nameEnglish
        nameArabic
        contact
        telephoneNumber
        supplierVATNo
        creditAmountLimit
        supplierGroupVATNumber
        invoicesCount
        supplierType {
          id
        }
        email
        fullAddress
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GetAll_Drivers = gql`
  query drivers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [DriverDTOSortInput!]
    $where: DriverDTOFilterInput
  ) {
    drivers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        driverNameArabic
        driverNameEnglish
        salary
        acommodation
        communication
        traveling
        deductions
        totalSalary
        phoneNumber
        iqamaNumber
        iqamaExpiryDate
        joiningDate
        licenseNo
        licenseExpiryDate
        status
        password
      }
    }
  }
`;

export const DRIVER_BY_ID = gql`
  query ($id: Int!) {
    driverById(id: $id) {
      id
      salary
      acommodation
      traveling
      communication
      deductions
      totalSalary
      driverNameArabic
      driverNameEnglish
      phoneNumber
      iqamaNumber
      iqamaExpiryDate
      joiningDate
      licenseNo
      licenseExpiryDate
      password
      status
    }
  }
`;
export const DRIVER_SALARY_RECORD = gql`
  query (
    $id: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: DriverSalaryRecordDTOFilterInput
    $order: [DriverSalaryRecordDTOSortInput!]
  ) {
    driverSalaries(
      id: $id
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        id
        month
        paymentMethod
        advanceAmount
        deductionAmount
        totalSalary
        paidAmount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_PURCHASES_INVOICES22 = gql`
  query (
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [PurchaseInvoiceDTOSortInput!]
    $where: PurchaseInvoiceDTOFilterInput
  ) {
    purchaseInvoices(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      nodes {
        issuedDate
        total
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_PURCHASES_INVOICES = gql`
  query (
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [PurchaseInvoiceDTOSortInput!]
    $where: PurchaseInvoiceDTOFilterInput
    $branchId: String
  ) {
    purchaseInvoices(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
      branchId: $branchId
    ) {
      totalCount
      nodes {
        id
        supplier {
          nameEnglish
          nameArabic
        }
        issuedDate
        total
        invoiceStatus
        supplierId
        issuedDate
        grossTotal
        returnAmount
        amountPaid
        discountPercentage
        total
        createdBy
        discountAmount
        purchaseInvoiceItems {
          quantity
          remainingQuantity
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;

export const ADMIN_GET_PURCHASES_INVOICES = gql`
  query (
    $branchId: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [PurchaseInvoiceDTOSortInput!]
    $where: PurchaseInvoiceDTOFilterInput
  ) {
    purchaseInvoices(
      branchId: $branchId
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      nodes {
        id
        supplier {
          nameEnglish
          nameArabic
        }
        issuedDate
        total
        invoiceStatus
        supplierId
        issuedDate
        grossTotal
        returnAmount
        amountPaid
        discountPercentage
        total
        createdBy
        discountAmount
        purchaseInvoiceItems {
          quantity
          remainingQuantity
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_PURCHASE_INVOICE_BY_ID = gql`
  query ($id: Int!) {
    purchaseInvoiceById(id: $id) {
      id
      termsAndConditions
      invoiceStatus
      amountPaid
      createdAt
      returnAmount
      issuedDate
      supplyDate
      paymentMethod
      paymentType
      taxId
      taxTreatmentNarration
      total
      discountAmount
      remainingAmount
      netTotal
      totalVAT
      grossTotal
      vATPercentage
      createdBy
      referenceNumber
      paymentType
      paymentMethod
      termsAndConditions
      netTotal
      supplier {
        id
        nameEnglish
        nameArabic
        fullAddress
        supplierVATNo
        supplierGroupVATNumber
        balance
      }
      sellMan {
        id
        nameEnglish
        nameArabic
      }
      tax {
        categoryCode
        rate
      }
      purchaseInvoiceItems {
        itemId
        item {
          itemNameEnglish
          itemNameArabic
          piecesPerUnit
          itemUnitOfMeasure {
            unitId
            piecesPerUnit
            baseUnit
            discountPercentage
            discountAmount
            nameEnglish
            nameArabic
            sellPrice
            isRetail
            costPrice
            piecesPerUnit
            baseUnit
          }
        }
        unit {
          id
          nameEnglish
          nameArabic
        }
        discountAmount
        discountPercentage
        quantity
        remainingQuantity
        returnedQuantity
        vATPercentage
        subTotalVATInclusive
        vATTotal
        costPrice
        item {
          itemNameEnglish
          itemNameArabic
          piecesPerUnit
        }
        tax {
          nameEnglish
          nameArabic
          rate
          categoryCode
        }
        unit {
          id
          nameEnglish
          nameArabic
        }
      }
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query suppliers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [SupplierDTOSortInput!]
    $where: SupplierDTOFilterInput
    $branchId: String
  ) {
    suppliers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
      branchId: $branchId
    ) {
      nodes {
        id
        nameEnglish
        nameArabic
        supplierSellMens {
          id
          nameEnglish
          nameArabic
        }
      }
    }
  }
`;

export const ADMIN_GET_SUPPLIERS = gql`
  query suppliers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [SupplierDTOSortInput!]
    $where: SupplierDTOFilterInput
    $branchId: String
  ) {
    suppliers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
      branchId: $branchId
    ) {
      nodes {
        id
        nameEnglish
        nameArabic
        supplierSellMens {
          id
          nameEnglish
          nameArabic
        }
      }
    }
  }
`;

export const PURCHASE_QUOTATION_LOAD = gql`
  query purchaseQuotation(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: PurchaseQuotationDTOFilterInput
    $order: [PurchaseQuotationDTOSortInput!]
  ) {
    purchaseQuotation(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        id
        itemsCount
        # terms
        # supplierId
        supplier {
          nameEnglish
          nameArabic
          contact
        }
        quotationItems {
          itemId
        }
        createdBy
        createdAt
        # grossTotal
        # netTotal
        # total
        # totalVAT
        # discountAmount
        # discountPercentage
        # referenceNo
        #lastUpdatedBy
        #lastUpdatedAt
        #isDeleted
        #deletedAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

// ####################
// Drivers Type Query #U
// ####################
export const GetDriverDataById = gql`
  query driverById($id: Int!) {
    driverById(id: $id) {
      employeeId
      employee {
        joiningDate
        iqamaNumber
        iqamaExpiryDate
        designation
        contact
        travelling
        communication
        basicSalary
        totalSalary
        nameArabic
        nameEnglish
        accomodation
        travelling
        communication
        basicSalary
        totalSalary
        status
      }
    }
  }
`;

export const GET_AUTOCOMPLETE_ITEMS = gql`
  query Items($where: ItemDTOFilterInput, $branchId: String) {
    items(where: $where, branchId: $branchId) {
      nodes {
        itemId
        itemNameEnglish
        itemNameArabic
        costPrice
        discountPercentage
        thresholdQuantity
        sellableOnHand
        sellableReserved
        shelfNumber
        location
        itemUnitOfMeasure {
          unitId
          nameArabic
          nameEnglish
          barCode
          netSellPrice
          sellPrice
          costPrice
          isRetail
          piecesPerUnit
          discountPercentage
          discountAmount
          baseUnit
        }
        tax {
          id
          categoryCode
          rate
        }
        hasVATInclSellPrice
        retailSellPrice
        retailSellNetPrice
        wholeSellPrice
        wholeSellNetPrice
        retailSellNetPrice
        barCode
      }
    }
  }
`;

export const ADMIN_GET_AUTOCOMPLETE_ITEMS = gql`
  query Items($where: ItemDTOFilterInput, $branchId: String) {
    items(where: $where, branchId: $branchId) {
      nodes {
        itemId
        itemNameEnglish
        itemNameArabic
        costPrice
        retailSellPrice
        discountPercentage
        thresholdQuantity
        sellableOnHand
        sellableReserved
        shelfNumber
        location
        itemUnitOfMeasure {
          unitId
          nameArabic
          nameEnglish
          barCode
          netSellPrice
          sellPrice
          costPrice
          isRetail
          piecesPerUnit
          discountPercentage
          discountAmount
          baseUnit
        }
        hasVATInclSellPrice
        retailSellPrice
        wholeSellPrice
        wholeSellNetPrice
        retailSellNetPrice
        barCode
      }
    }
  }
`;

// Supplier by ID

export const Supplier_By_ID = gql`
  query supplierById($id: Int!) {
    supplierById(id: $id) {
      id
      nameArabic
      nameEnglish
      email
      creditAmountLimit
      balance
      supplierTypeId
      contact
      email
      creditAmountLimit
      accountId
      supplierType {
        nameEnglish
        nameArabic
        id
      }

      telephoneNumber
      supplierVATNo
      fullAddress
      supplierSellMens {
        nameEnglish
        id
        nameArabic
        contact
      }
    }
  }
`;

export const PURCHASE_QUOTATION_BY_ID = gql`
  query ($id: Int!) {
    purchaseQuotationById(id: $id) {
      id
      createdBy
      createdAt
      itemsCount
      supplier {
        nameArabic
        nameEnglish
      }

      total
      totalVAT
      netTotal
      grossTotal
      discountAmount

      quotationItems {
        totalVAT
        discountTotal
        total
        totalVATIncl
        price
        quantity
        vATPercentage

        unit {
          nameEnglish
          nameArabic
        }
        item {
          itemNameEnglish
          itemId
        }
      }
    }
  }
`;

// =================                      =====================
// =================Purchase Expenditures #U===================
// =================                      =====================
export const GetAll_Expenditures = gql`
  query expenditures(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [ExpenditureDtoSortInput!]
    $where: ExpenditureDtoFilterInput
  ) {
    expenditures(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
      }
      nodes {
        id
        title
        amount
        referenceNo
        description
        issueDate
        debitAccount {
          id
          nameEnglish
          debit
        }
        creditAccount {
          id
          nameEnglish
        }
      }
    }
  }
`;

// =================                      =====================
// =================Get Supplier types   #U====================
// =================                      =====================
export const Get_SupplierTypes = gql`
  query supplierTypes {
    supplierTypes {
      id
      nameEnglish
      nameArabic
    }
  }
`;

export const GET_CATEGORIES = gql`
  query ($branchId: String) {
    categories(branchId: $branchId) {
      id
      categoryNameArabic
      categoryNameEnglish
    }
  }
`;

export const ADMIN_GET_CATEGORIES = gql`
  query ($branchId: String) {
    categories(branchId: $branchId) {
      id
      categoryNameArabic
      categoryNameEnglish
    }
  }
`;

export const GET_UNITS = gql`
  query ($branchId: String) {
    units(branchId: $branchId) {
      id
      nameEnglish
      nameArabic
    }
  }
`;

export const ADMIN_GET_UNITS = gql`
  query ($branchId: String) {
    units(branchId: $branchId) {
      id
      nameEnglish
      nameArabic
    }
  }
`;

// ================= LOAD SALARY RECORD BY ID
export const LOAD_EMPLOYEE_SALARIES_BY_ID = gql`
  query (
    $id: Int!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: SalaryRecordDTOFilterInput
    $order: [SalaryRecordDTOSortInput!]
  ) {
    employeeSalaries(
      id: $id
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        advanceAmount

        paymentMethod

        month

        paidAmount
        basicSalary
        totalSalary
        deductionAmount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
//=============== Item History Price
export const ITEM_HISTORY_PRICE = gql`
  query (
    $before: String
    $where: ItemPriceHistoryDTOFilterInput
    $order: [ItemPriceHistoryDTOSortInput!]
    $first: Int
    $after: String
    $itemId: Int!
    $last: Int
  ) {
    itemPriceHistory(
      itemId: $itemId
      after: $after
      before: $before
      last: $last
      order: $order
      where: $where
      first: $first
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        itemId
        date
        retailCostPrice
        retailSellPrice
        wholeSellPrice
      }
    }
  }
`;

//==========  Item movement
export const ITEM_MOVEMENT_BY_ID = gql`
  query (
    $itemId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: InventoryMovementDTOFilterInput
    $order: [InventoryMovementDTOSortInput!]
  ) {
    itemMovement(
      itemId: $itemId
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      nodes {
        date
        itemId
        quantity
        costPrice
        movementType
        item {
          onHand
          location
        }
      }
    }
  }
`;

export const GET_SALES_INVOICES = gql`
  query (
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [SaleInvoiceDtoSortInput!]
    $where: SaleInvoiceDtoFilterInput
    $branchId: String
  ) {
    saleInvoices(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
      branchId: $branchId
    ) {
      nodes {
        id
        customerName
        invoiceType
        grossTotal
        issuedDate
        status
        saleInvoiceClearenceStatus
        customer {
          nameEnglish
          nameArabic
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GET_ADMIN_SALES_INVOICES = gql`
  query (
    $branchId: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [SaleInvoiceDtoSortInput!]
    $where: SaleInvoiceDtoFilterInput
  ) {
    saleInvoices(
      branchId: $branchId
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      nodes {
        id
        customerName
        grossTotal
        itemQuantity
        issuedDate
        discountAmount
        createdBy
        saleInvoiceType
        invoiceItems {
          itemId
        }
        customer {
          nameArabic
          nameEnglish
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GET_SALE_INVOICE_BY_ID = gql`
  query ($id: String!) {
    saleInvoiceById(invoiceId: $id) {
      id
      supplyDate
      issuedDate
      createdBy
      createdAt
      customerName
      termsAndConditions
      saleInvoiceType
      customerAddress
      customerVATNumber
      customerGroupVATNumber
      sellerName
      sellerAddress
      sellerVATNumber
      sellerGroupVATNumber
      qrCode
      returnAmount
      status
      paymentMethod
      paymentType
      total
      discountAmount
      discountPercentage
      netTotal
      vatPercentage
      grossTotal
      totalVAT
      netTotal
      amountPaid
      remainingAmount
      uuid
      saleInvoiceClearenceStatus
      saleInvoiceSpecialBillingAgreement
      saleInvoiceSpecialTransactionType
      invoiceType
      vatCategoryCode
      saleMan {
        id
        nameArabic
        nameEnglish
      }
      invoiceItems {
        itemId
        sellPrice
        quantity
        remainingQuantity
        returnedQuantity
        vATCategoryCode
        vATPercentage
        vATTotal
        discountAmount
        discountPercentage
        subTotalVATInclusive
        total
        netTotal
        unitId
        item {
          itemNameEnglish
          itemNameArabic
          location
          itemId
          itemUnitOfMeasure {
            unitId
            piecesPerUnit
            baseUnit
            discountPercentage
            discountAmount
            nameEnglish
            nameArabic
            sellPrice
            isRetail
            costPrice
            piecesPerUnit
          }
        }
        unit {
          id
          nameEnglish
          nameArabic
        }
      }
      customer {
        id
        nameEnglish
        nameArabic
        balance
        street
        city
        province_State
        countryCode
      }
      invoiceServices {
        invoiceId
        serviceId
        returnedQuantity
        quantity
        remainingQuantity
        total
        netTotal
        discountAmount
        discountPercentage
        vATPercentage
        vATTotal
        vATCategoryCode
        subTotalVATInclusive
        price
        service {
          hours
          serviceId
          price
          serviceNameEnglish
          serviceNameArabic
        }
      }
    }
  }
`;

export const GET_SALE_RETURN_VOUCHER_BY_ID = gql`
  query ($id: Int!) {
    saleReturnById(voucherId: $id) {
      saleInvoice {
        customerName
        createdBy
        issuedDate
        customerAddress
        customerVATNumber
        customerGroupVATNumber
        amountPaid
        remainingAmount
        returnAmount
      }
      returnItems {
        itemId
        item {
          itemNameArabic
          itemNameEnglish
        }
        sellPrice
        saleInvoiceItem {
          remainingQuantity
          quantity
        }
        quantity
        total
      }
    }
  }
`;

export const GET_SALE_DELIVERIES_BY_INVOICE_ID = gql`
  query ($id: String!) {
    saleInvoiceDeliverySchedule(invoiceId: $id) {
      itemId
      itemNameArabic
      itemNameEnglish
      remainingQuantity
      totalQuantity
      dates {
        date
        quantity
      }
    }
  }
`;

export const GET_SALE_DELIVERY_BY_ID = gql`
  query ($id: Int!) {
    saleDeliveryById(deliveryId: $id) {
      saleInvoice {
        customerName
        createdBy
        issuedDate
        customerAddress
        customerVATNumber
        customerGroupVATNumber
      }
      saleDeliveryItems {
        itemId
        item {
          itemNameArabic
          itemNameEnglish
        }
        saleInvoiceItem {
          remainingQuantity
          quantity
          unit {
            nameEnglish
          }
        }
        quantity
      }
    }
  }
`;

export const GET_SALE_INVOICE_ITEMS_BY_INVOICE_ID = gql`
  query ($id: String!) {
    saleInvoiceById(invoiceId: $id) {
      id
      saleInvoiceType
      invoiceItems {
        returnedQuantity
        item {
          itemNameEnglish
          itemNameArabic
          location
          itemId
        }
        unit {
          nameEnglish
        }
        sellPrice
        quantity
        remainingQuantity
      }
      amountPaid
      remainingAmount
    }
  }
`;
export const GET_SALE_INVOICE__DELIVERY_ITEMS_BY_INVOICE_ID = gql`
  query ($id: String!) {
    saleInvoiceById(invoiceId: $id) {
      id
      saleInvoiceType
      customer {
        fullAddress
      }
      invoiceItems {
        itemId
        item {
          itemId
          itemNameArabic
          itemNameEnglish
        }
        remainingQuantity
        quantity
      }
    }
  }
`;

export const GET_AVAILABLE_DRIVERS = gql`
  query {
    drivers(first: 50, where: { status: { eq: AVAILABLE } }) {
      nodes {
        id
        employee {
          nameArabic
          nameEnglish
          contact
        }
        status
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query ($search: String!, $branchId: String) {
    customers(
      branchId: $branchId
      where: {
        or: [
          { nameArabic: { contains: $search } }
          { nameEnglish: { contains: $search } }
        ]
      }
    ) {
      nodes {
        id
        nameEnglish
        nameArabic
        creditAmountLimit
        balance
      }
      pageInfo {
        hasNextPage
        startCursor
      }
    }
  }
`;

export const ADMIN_GET_CUSTOMERS = gql`
  query ($search: String!, $branchId: String) {
    customers(
      where: {
        or: [
          { nameArabic: { contains: $search } }
          { nameEnglish: { contains: $search } }
        ]
      }
      branchId: $branchId
    ) {
      nodes {
        id
        nameEnglish
        nameArabic
        creditAmountLimit
        balance
      }
      pageInfo {
        hasNextPage
        startCursor
      }
    }
  }
`;

export const LOAD_CUSTOMERS = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: CustomerDtoFilterInput
    $order: [CustomerDtoSortInput!]
  ) {
    customers(
      first: $first

      after: $after

      last: $last

      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        id
        vATNo
        groupVatNumber
        responsibleNameArabic
        responsibleNameEnglish
        responsibleContact
        accountId
        nameArabic
        nameEnglish
        fullAddress
        contact
        telephone
        email
        creditAmountLimit
        balance
        customerTypeId
        customerType {
          nameArabic
          id
          nameEnglish
        }
        invoicesCount
        identification
        identificationType
        street
        additionalStreet
        buildingNumber
        city
        postalCode
        province_State
        district
        countryCode
        plotNumber
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

// ============ Get All Inovice Summary =================
export const GET_Invoice_Summary = gql`
  query supplierInvoiceSummary($supplierId: Int!) {
    supplierInvoiceSummary(supplierId: $supplierId) {
      allCount
      allAmount
      partiallyAmount
      partiallyCount
      unpaidAmount
      paidAmount
      paidCount
      totalVat
      unpaidCount
    }
  }
`;
// ============ Get All Target =================
export const GET_ALL_Target = gql`
  query supplierTargets(
    $supplierId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: SupplierTargetDTOFilterInput
    $order: [SupplierTargetDTOSortInput!]
  ) {
    supplierTargets(
      supplierId: $supplierId
      first: $first

      after: $after

      last: $last

      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        id
        issueDate
        status
        supplierId
        discountPercentage
        targetAmount
        deadlineDate
        description
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const CUSTOMER_BY_ID = gql`
  query ($id: Int!) {
    customerById(id: $id) {
      id
      vATNo
      groupVatNumber
      responsibleNameArabic
      responsibleNameEnglish
      responsibleContact
      accountId
      nameArabic
      nameEnglish
      fullAddress
      contact
      telephone
      email
      creditAmountLimit
      balance
      customerTypeId
      customerType {
        nameArabic
        id
        nameEnglish
      }
      invoicesCount
      identification
      identificationType
      street
      additionalStreet
      buildingNumber
      city
      postalCode
      province_State
      district
      countryCode
      plotNumber
    }
  }
`;

export const PURCHASE_RETURNS_BY_INVOICE_ID = gql`
  query ($invoiceId: Int!) {
    purchaseReturnsByInvoiceId(invoiceId: $invoiceId) {
      id
      createdBy
      createdAt
    }
  }
`;

// Invoice outstanding = gross total - amountPaid
export const PURCHASE_RETURN_BY_ID1 = gql`
  query ($returnId: Int!) {
    purchaseReturnById(returnId: $returnId) {
      id
      total
      vATotal
      grossTotal
      createdAt
      createdBy
      reason
      paymentMethod
      invoice {
        id
        amountPaid
        grossTotal
        supplier {
          nameEnglish
          nameArabic
          fullAddress
          supplierVATNo
          supplierGroupVATNumber
        }
      }
      items {
        itemId
        quantity
        costPrice
        total
        vATTotal
        purchaseInvoiceItem {
          quantity
          remainingQuantity
        }
        item {
          itemNameEnglish
          itemNameArabic
        }
      }
    }
  }
`;

export const PURCHASE_RECEIVE_BY_ID = gql`
  query ($voucherId: Int!) {
    purchaseReceiveById(voucherId: $voucherId) {
      id
      invoiceId
      issueDate
      createdBy
      purchaseInvoice {
        id
        supplier {
          nameEnglish
          nameArabic
          fullAddress
          supplierVATNo
          supplierGroupVATNumber
        }
      }
      items {
        itemId
        receiveQuantity
        item {
          itemNameEnglish
          itemNameArabic
        }
        purchaseInvoiceItem {
          costPrice
          quantity
          remainingQuantity
        }
      }
    }
  }
`;

export const CUSTOMER_INVOICE_SUMMARY = gql`
  query ($customerId: Int!) {
    customerInvoiceSummary(customerId: $customerId) {
      allCount
      allAmount
      paidAmount
      paidCount
      partiallyAmount
      partiallyCount
      unpaidAmount
      unpaidCount
      totalVat
    }
  }
`;

export const TARGET_BY_CUSTOMER_ID = gql`
  query (
    $customerId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: CustomerTargetDtoFilterInput
    $order: [CustomerTargetDtoSortInput!]
  ) {
    customerTargets(
      customerId: $customerId
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        customerId
        issueDate
        deadlineDate
        description
        targetAmount
        discountPercentage
        status
      }
    }
  }
`;

//====== Cutomer Project Queries
export const LOAD_CUSTOMER_PROJECTS = gql`
  query (
    $customerId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: CustomerProjectDtoFilterInput
    $order: [CustomerProjectDtoSortInput!]
  ) {
    customerProjects(
      customerId: $customerId
      first: $first
      last: $last
      order: $order
      where: $where
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        creditLimit
        nameEnglish
        nameArabic
        description
        responsibleNameArabic
        responsibleNameEnglish
        address
        responsibleContact
        paymentDueDate
      }
    }
  }
`;

//=================== Customer Invoice ==========
export const LOAD_CUSTOMER_INVOICE = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: SaleInvoiceDtoFilterInput
    $order: [SaleInvoiceDtoSortInput!]
  ) {
    saleInvoices(
      first: $first
      last: $last
      before: $before
      after: $after
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      nodes {
        id
        issuedDate
        grossTotal
        discountAmount
        remainingAmount
        total
        itemQuantity
        remainingQuantity
        invoiceItems {
          itemId
        }
      }
    }
  }
`;

//==================== Customer Clearance ==============
export const LOAD_CUSTOMER_CLEARANCE = gql`
  query (
    $customerId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: CustomerClearanceVoucherDtoFilterInput
    $order: [CustomerClearanceVoucherDtoSortInput!]
  ) {
    clearanceVouchers(
      customerId: $customerId
      first: $first
      after: $after
      last: $last
      where: $where
      order: $order
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        issueDate
        paymentMethod
        createdBy
        customer {
          fullAddress
          nameArabic
          groupVatNumber
          vATNo
          id
          nameEnglish
        }
        clearedInvoices {
          amountPaid
          voucherId
          invoiceId
          saleInvoice {
            id
            returnAmount
            remainingAmount
            issuedDate
            project {
              id
              nameArabic
              nameEnglish
            }
            total
            grossTotal
            remainingAmount
            returnAmount
            amountPaid
            receiveAs
            netTotal
          }
          amountPaid
        }
      }
    }
  }
`;

export const ADD_CUSTOMER_CLEARANCE_MODAL_DATA = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: SaleInvoiceDtoFilterInput
    $order: [SaleInvoiceDtoSortInput!]
  ) {
    saleInvoices(
      first: $first
      after: $after
      before: $before
      last: $last
      order: $order
      where: $where
    ) {
      nodes {
        id
        status
        grossTotal
        returnAmount
        remainingAmount
      }
    }
  }
`;

export const PROJECT_BY_ID = gql`
  query ($projectId: Int!) {
    customerProjectById(projectId: $projectId) {
      id
      creditLimit
      creditConsumed
      paymentDueDate
      status
      customerId
      customer {
        id
        nameEnglish
        fullAddress
      }
      nameEnglish
      nameArabic
      address
      description
      responsibleNameArabic
      responsibleNameEnglish
      responsibleContact
    }
  }
`;

export const GET_SALES_DELIVERIES = gql`
  query (
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: SaleDeliveryDtoFilterInput
    $order: [SaleDeliveryDtoSortInput!]
  ) {
    saleDeliveries(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        id
        invoiceId
        createdBy
        status
        saleInvoice {
          grossTotal
          customer {
            nameEnglish
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const SALE_DELIVERY_BY_ID = gql`
  query ($deliveryId: Int!) {
    saleDeliveryById(deliveryId: $deliveryId) {
      id
      invoiceId
      createdAt
      createdBy
      shippingAddress
      driver {
        driverNameArabic
        driverNameEnglish
      }
      saleInvoice {
        id
        customerName
        customerVATNumber
        customerGroupVATNumber
        customerAddress
        customer {
          nameEnglish
        }
        totalVAT
        discountAmount
        total
        remainingAmount
        amountPaid
        grossTotal
      }
      saleDeliveryItems {
        deliveryId
        itemId
        quantity
        saleInvoiceItem {
          # itemId
          # sellPrice
          # quantity
          # discountPercentage
          # total
          # itemDescription
          # costPrice
          # returnedQuantity
          # quantity
          # remainingQuantity
          # total
          unit {
            nameEnglish
            nameArabic
          }
          item {
            itemId
            itemNameEnglish
            itemNameArabic
          }
        }
      }
    }
  }
`;

export const SALE_DELIVERY_SUMMARY = gql`
  query {
    saleDeliverySummary {
      total
      delivered
      notDelivered
      delivering
      pending
      scheduled
    }
  }
`;

export const SALE_INVOICE_BY_ID2 = gql`
  query ($invoiceId: String!) {
    saleInvoiceById(invoiceId: $invoiceId) {
      saleInvoiceType
      invoiceItems {
        quantity
        remainingQuantity
        alreadySchedule
        unit {
          id
          nameEnglish
          nameArabic
        }
        unitId
        item {
          itemNameEnglish
          itemNameArabic
          itemId
          location
          shelfNumber
          itemUnitOfMeasure {
            unitId
            piecesPerUnit
            baseUnit
            discountPercentage
            discountAmount
            nameEnglish
            nameArabic
            sellPrice
            isRetail
            costPrice
            piecesPerUnit
            baseUnit
          }
        }
      }
    }
  }
`;

export const ALL_ITEMS = gql`
  query ($where: ItemDTOFilterInput, $order: [ItemDTOSortInput!]) {
    allItems(order: $order, where: $where) {
      itemId
      onHand
      barCode
      itemNameEnglish
      itemNameArabic
      itemUnitOfMeasure {
        barCode
        sellPrice
        nameEnglish
        nameArabic
      }
    }
  }
`;

export const PRINT_BAR_CODE_QUERY = gql`
  query items(
    $first: Int
    $after: String
    $before: String
    $where: ItemDTOFilterInput
    $order: [ItemDTOSortInput!]
    $last: Int
  ) {
    items(
      first: $first
      after: $after
      before: $before
      where: $where
      order: $order
      last: $last
    ) {
      totalCount
      nodes {
        itemId
        itemNameArabic
        itemNameEnglish

        barCode
        onHand
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
//======================== settings / Preferances
export const LOAD_SUPPLIER_TYPES = gql`
  query {
    supplierTypes {
      id
      nameEnglish
      nameArabic
    }
  }
`;

export const LOAD_CATEGORIES_TYPES = gql`
  query {
    categories {
      id
      categoryNameArabic
      categoryNameEnglish
    }
  }
`;
export const LOAD_CUSTOMER_TYPES = gql`
  query {
    customerTypes {
      id
      nameArabic
      nameEnglish
    }
  }
`;
export const LOAD_ACCOUNT_TYPES = gql`
  query ($accountType: AccountType!) {
    accountSubTypes(accountType: $accountType) {
      id
      nameArabic
      nameEnglish
    }
  }
`;

export const LOAD_DEFAULT_ZAQAT = gql`
  query {
    defaultZakat {
      id
      rate
      isDefault
      taxChangeHistories {
        oldValue
        newValue
        createdBy
        createdAt
        isDeleted
        deletedAt
      }
    }
  }
`;
export const LOAD_DEFAULT_TAX = gql`
  query {
    defaultTax {
      id
      rate
      isDefault
      taxChangeHistories {
        oldValue
        newValue
        createdBy
        createdAt
        lastUpdatedBy
      }
    }
  }
`;

export const LOAD_CONFIGURATIONS = gql`
  query {
    configurations {
      id
      value
    }
  }
`;

export const ADMIN_LOAD_CONFIGURATIONS = gql`
  query ($branchId: String) {
    configurations(branchId: $branchId) {
      id
      value
    }
  }
`;

export const LOAD_USERS = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: ApplicationUserFilterInput
    $order: [ApplicationUserSortInput!]
  ) {
    users(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        userName
        counterId
        isCashier
        roleId
      }
    }
  }
`;

export const LOAD_ROLES = gql`
  query (
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: ApplicationRoleDtoFilterInput
    $order: [ApplicationRoleDtoSortInput!]
  ) {
    roles(
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        __typename
      }
      nodes {
        id
        nameEnglish
        nameArabic
        descriptionEnglish
        descriptionArabic
        lastUpdated
        __typename
      }
      __typename
    }
  }
`;

export const LOAD_ROLES_FOR_SELECT = gql`
  query ($first: Int) {
    roles(first: $first) {
      nodes {
        id
        nameEnglish
        nameArabic
      }
      __typename
    }
  }
`;

export const LOAD_PERMISSIONS = gql`
  query {
    permissions {
      title
      key
      children {
        title
        key
        readPermission
        writePermission
        deletePermission
      }
    }
  }
`;
export const LOAD_PERMISSIONS_BY_ROLE_ID = gql`
  query ($input: String!) {
    rolePermissions(roleId: $input)
  }
`;

export const GET_USERS = gql`
  query ($search: String!, $branchId: String) {
    users(
      branchId: $branchId
      where: { or: [{ userName: { contains: $search } }] }
    ) {
      nodes {
        userName
        id
      }
    }
  }
`;

export const LOAD_BUSINESS_INFO = gql`
  query {
    businessInfo {
      id
      imageId
      nameEnglish
      nameArabic
      email
      telephone
      mobile
      street
      additionalStreet
      buildingNumber
      plotNumber
      city
      province_State
      district
      postalCode
      countryCode
      vatNumber
      groupVatNumber
      cr
      addressEnglish
      addressArabic
      organizationUnitName
      industry
    }
  }
`;

export const VAT_RATE = gql`
  query {
    defaultTax {
      rate
    }
  }
`;

export const ADMIN_VAT_RATE = gql`
  query ($branchId: String) {
    defaultTax(branchId: $branchId) {
      rate
    }
  }
`;

//==================== Load Notes
export const NOTES = gql`
  query {
    notes {
      id
      description
      createdAt
      title
    }
  }
`;

export const LOAD_REMINDER = gql`
  query {
    reminders {
      id
      name
      remarks
      remindOn
    }
  }
`;

export const PROJECT_BY_CUSTOMER_ID = gql`
  query ($customerId: Int!) {
    customerProjects(
      where: { status: { eq: ACTIVE } }
      customerId: $customerId
    ) {
      nodes {
        id
        nameEnglish
        nameArabic
      }
    }
  }
`;

export const SUPPLIERS_EMAIL = gql`
  query {
    allSuppliers(where: { email: { neq: null } }) {
      id
      email
    }
  }
`;

export const BranchesList = gql`
  query ($first: Int, $where: BranchDtoFilterInput) {
    branches(first: $first, where: $where) {
      totalCount
      nodes {
        id
        branchNameEnglish
        branchNameArabic
      }
    }
  }
`;

export const VAT_CATEGORY_CODES = gql`
  query ($branchId: String) {
    vatCategories(branchId: $branchId) {
      nameArabic
      nameEnglish
      id
      categoryCode
      rate
    }
  }
`;

export const Exemption_Reason = gql`
  query (
    $where: TaxExemptionReasonDtoFilterInput
    $order: [TaxExemptionReasonDtoSortInput!]
  ) {
    taxExemptionReasons(where: $where, order: $order) {
      id
      code
      reasonCode
      text
      detail
    }
  }
`;
//==================================
//            Commission
//==================================
export const ALL_ITEMS_COMMISSION = gql`
  query ($where: ItemDTOFilterInput, $order: [ItemDTOSortInput!]) {
    allItems(order: $order, where: $where) {
      itemId
      onHand
      barCode
      itemNameEnglish
      itemNameArabic
      itemUnitOfMeasure {
        barCode
        sellPrice
        nameEnglish
        nameArabic
      }
    }
  }
`;

export const GET_COMMISSION = gql`
  query (
    $branchId: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: CommissionDtoFilterInput
    $order: [CommissionDtoSortInput!]
  ) {
    commission(
      branchId: $branchId
      first: $first
      after: $after
      before: $before
      where: $where
      order: $order
      last: $last
    ) {
      totalCount
      nodes {
        id
        commissionNameArabic
        commissionNameEnglish
        commissionRules
        commissionPercentage
        commissionPeriod
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
export const GET_COMMISSION_BY_ID = gql`
  query ($commissionId: Int!) {
    commissionById(commissionId: $commissionId) {
      id
      createdBy
      commissionNameArabic
      commissionNameEnglish
      commissionRules
      targetRevenue
      targetVolume
      commissionPeriod
      commissionPercentage
      commissionItem {
        itemId
        item {
          itemNameArabic
          itemNameEnglish
          categoryID
        }
      }
      commissionCategory {
        categoryId
        category {
          categoryNameArabic
          categoryNameEnglish
        }
      }
    }
  }
`;

export const GET_COMMISSIONED_EMPLOYEE = gql`
  query (
    $first: Int
    $last: Int
    $before: String
    $after: String
    $where: HRMEmployeeDtoFilterInput
    $order: [HRMEmployeeDtoSortInput!]
  ) {
    hRMEmployees(
      first: $first
      last: $last
      before: $before
      after: $after
      where: $where
      order: $order
    ) {
      totalCount
      nodes {
        id
        nameArabic
        nameEnglish
        commission {
          id
          commissionNameArabic
          commissionNameEnglish
        }
      }
    }
  }
`;
export const GET_ITEMS_ALL_COMMISSION = gql`
  query (
    $branchId: String
    $where: ItemDTOFilterInput
    $order: [ItemDTOSortInput!]
  ) {
    allItems(branchId: $branchId, where: $where, order: $order) {
      itemId
      itemNameArabic
      itemNameEnglish
      categoryID
    }
  }
`;

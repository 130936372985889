import { useLazyQuery } from '@apollo/client';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import {
  ADMIN_LOAD_CONFIGURATIONS,
  LOAD_CONFIGURATIONS,
} from '../GraphQL/Queries';
import { useGlobalData } from '../Store/store';
import { useAuthContext } from './authContext';

const configContext = createContext();
export const useConfigContext = () => useContext(configContext);

const ConfigProvider = (props) => {
  // const [sysConfig, setSysConfig] = useState({});
  const { selectedBranch } = useGlobalData();
  const { getCurrentUserName } = useAuthContext();
  const [data, setData] = useState();
  //==================================
  //            Queries
  //==================================

  const [adminConfig, { loading }] = useLazyQuery(LOAD_CONFIGURATIONS);

  const [superAdminConfig] = useLazyQuery(ADMIN_LOAD_CONFIGURATIONS, {
    variables: {
      branchId: selectedBranch?.value,
    },
  });

  //==================================
  //            UseEffect
  //==================================
  useEffect(() => {
    //if there is branchId then only super admin cconfig will be called
    getCurrentUserName() === 'superadmin'
      ? superAdminConfig().then(({ data }) => (data ? setData(data) : ''))
      : adminConfig().then(({ data }) => (data ? setData(data) : ''));
  }, []);

  const refetchConfig = () => {
    getCurrentUserName() === 'superadmin'
      ? superAdminConfig().then(({ data }) => (data ? setData(data) : ''))
      : adminConfig().then(({ data }) => (data ? setData(data) : ''));
  };
  //==================================
  //            Cloning Data in key value
  //==================================
  const sysConfig = useMemo(() => {
    return data?.configurations?.reduce(
      (previousValue, currentValue) =>
        Object.assign(previousValue, {
          [currentValue.id]:
            currentValue.value === 'true'
              ? true
              : currentValue.value === 'false'
              ? false
              : currentValue.value,
        }),
      {},
    );
  }, [data]);

  // const sysConfigAdmin = useMemo(() => {
  //   return data1?.configurations.reduce(
  //     (previousValue, currentValue) =>
  //       Object.assign(previousValue, {
  //         [currentValue.id]:
  //           currentValue.value === 'true'
  //             ? true
  //             : currentValue.value === 'false'
  //             ? false
  //             : currentValue.value,
  //       }),
  //     {},
  //   );
  // }, [data1]);

  // console.log("config", sysConfig);

  const printStyle = `@media print{
    @page {
      size: ${sysConfig?.PageType} ${sysConfig?.PrintLayout};
    }
  }`;

  // const value = useMemo(
  //   () => ({
  //     sysConfig: sysConfig,
  //     printStyle: printStyle,
  //     refetchConfig: refetchConfig,
  //   }),
  //   [sysConfig, printStyle, refetchConfig],
  // );

  // console.log(sysConfig);
  return (
    <configContext.Provider value={{ sysConfig, printStyle, refetchConfig }}>
      {!loading && props.children}
    </configContext.Provider>
  );
};

export default ConfigProvider;

//================================================
// e.g: setting print layout and page size etc
//================================================

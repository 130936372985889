import React, { createContext, useState, useContext } from 'react';
import { permissionsStore, useZusAuth } from '../Store/store';

const authContext = createContext();
authContext.displayName = 'AuthContext';
export const useAuthContext = () => useContext(authContext);

export default function AuthProvider(props) {
  const { setPermissions } = permissionsStore();
  const { setBranchName } = useZusAuth();

  const [user, setUser] = useState(
    localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null,
  );

  const [UserPermissions, setUserPermissions] = useState(
    localStorage.getItem('userPermissions')
      ? JSON.parse(localStorage?.getItem('userPermissions'))
      : null,
  );

  // useEffect(() => {
  //   if (MyPermission) {
  //     // console.log(MyPermission, " :: updated  user permissions");
  //     console.log("permission changed");
  //     updateUserPermissons(MyPermission?.myPermissions);
  //   }
  // }, [MyPermission]);

  // update login info in session storage
  const login = (data) => {
    localStorage.setItem('user', JSON.stringify(data));

    data?.branchName &&
      window.localStorage.setItem('branchName', data?.branchName);
    data?.branchName && setBranchName(localStorage?.branchName);

    setUser(data);
  };
  const updateUserPermissons = (data) => {
    localStorage.setItem('userPermissions', JSON.stringify(data));
    setPermissions(data);
    setUserPermissions(data);
  };

  // clear session storage data
  const logout = () => {
    localStorage.clear();
    setUser(null);
    setUserPermissions(null);
    window.location.href = '/login';
  };

  // return current user data
  const getCurrentUser = () => {
    return user;
  };

  const getCurrentUserName = () => {
    return user?.userName;
  };

  // login check
  const isAuthenticated = () => {
    return user?.token ? true : false;
  };

  // return current user token
  const getAuthenticationToken = () => {
    return user?.token ? user?.token : null;
  };
  // return current user token
  const getRole = () => {
    return user?.role ? user?.role : null;
  };
  // return current user Permissions
  const getUserPermissions = () => {
    return UserPermissions;
  };

  return (
    <authContext.Provider
      value={{
        login,
        logout,
        getCurrentUser,
        isAuthenticated,
        getAuthenticationToken,
        getRole,
        updateUserPermissons,
        getUserPermissions,
        UserPermissions,
        getCurrentUserName,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
}

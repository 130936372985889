import create from 'zustand';

//====================================
//          Roles and Permissions
//====================================
export const useStore = create((set) => ({
  permissionKeys: [],
  indeterminateKeys: [],
  setPermissionKeys: (keys) => set((state) => ({ permissionKeys: keys })),
  setIndeterminateKeys: (keys) => set((state) => ({ indeterminateKeys: keys })),
}));

//====================================
//          Filters
//====================================
export const filterStore = create((set) => ({
  useFilter: { after: null, currentPage: null, before: null },
  setUseFilter: (keys) => set((state) => ({ useFilter: keys })),
}));

//====================================
//          Permissions
//====================================
export const permissionsStore = create((set) => ({
  permnissions: [],
  setPermissions: (permissions) => set((state) => permissions),
}));

//====================================
//          POS store
//====================================
export const usePosStore = create((set) => ({
  cartItems: [],
  products: [],
  headerOptions: [],
  setCartItems: (keys) => set((state) => ({ cartItems: keys })),
  setProducts: (keys) => set((state) => ({ products: keys })),
  setHeaderOptions: (keys) => set((state) => ({ headerOptions: keys })),
}));

//====================================
//          ScaleAble barcode Holder (Solud be removed in reefactoring phase)
//====================================
export const useZusBarCode = create((set) => ({
  zusBarCode: null,
  setZusBarCode: (input) => set((state) => ({ zusBarCode: input })),
}));

//====================================
//          Branch Store
//====================================
export const branchStore = create((set) => ({
  currentBranch: window.localStorage.getItem('currentBranch')
    ? window.localStorage.getItem('currentBranch')
    : '',
  setcurrentBranch: (value) => set((state) => ({ currentBranch: value })),
}));

//====================================
//          Dashboard Branch Id
//====================================
export const useDashboard = create((set) => ({
  branchId: null,
  setBranch: (input) => set((state) => ({ branchId: input })),
}));

//====================================
//          Branch Name
//====================================
export const useZusAuth = create((set) => ({
  branchName: '',
  setBranchName: (input) => set((state) => ({ branchName: input })),
}));

//====================================
//          Branch Id and Branch ID Selector (navbar)
//====================================
export const useGlobalData = create((set) => ({
  selectedBranch: null,
  setSelectedBranch: (input) => set((state) => ({ selectedBranch: input })),
  selector: false,
  setSelector: (input) => set((state) => ({ selector: input })),
}));

//====================================
//          SaleInv DataStore
//====================================
export const useSaleInvoice = create((set) => ({
  form: null,
  setForm: (input) => set((state) => ({ form: input })),

  transactionType: '',
  setTransactionType: (input) => set((state) => ({ transactionType: input })),
}));

//====================================
//          ----
//====================================
export const useAllData = create((set) => ({
  deleteCheck: false,
  setDeleteCheck: (input) => set((state) => ({ deleteCheck: input })),
}));
//====================================
//          VatRate
//====================================
export const vatRateStore = create((set) => ({
  vatRate: false,
  setvatRate: (input) => set((state) => ({ vatRate: input })),
}));
//====================================
//          Vat Categories
//====================================
export const vatCategoryStore = create((set) => ({
  vatCategories: [],
  setVatCategories: (input) => set({ vatCategories: input }),
}));

import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// import strings from "./../Languages/Languages";

const languageContext = createContext();
export const useLanguage = () => useContext(languageContext);

export default function LanguageProvider(props) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(
    sessionStorage.getItem('language')
      ? sessionStorage.getItem('language')
      : 'en',
  );

  React.useEffect(() => {
    // strings.setLanguage(language);
    // i18n.changeLanguage(language);
    let element = window.document.getElementsByTagName('body');
    element[0].setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [language]);

  const isRTL = React.useMemo(() => {
    switch (language) {
      case 'en':
        return false;
      case 'ar':
        return true;
      case 'ur':
        return true;
      case 'fr':
        return false;
      default:
        return false;
    }
  }, [language]);

  const updateLanguage = (current) => {
    sessionStorage.setItem('language', current);
    localStorage.setItem('language', current);
    i18n.changeLanguage(current);
    setLanguage(current);
  };

  return (
    <languageContext.Provider value={{ language, updateLanguage, isRTL }}>
      {props?.children}
    </languageContext.Provider>
  );
}

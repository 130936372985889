import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./../Context/authContext";
import NoRouteMatch from "./NoRouteMatch";

// Wrapper for Route component that checks if user is authenticated
const ProtectedRoute = ({ routes }) => {
  const { isAuthenticated } = useAuthContext();

  return (
    <>
      <Routes>
        {routes.map((route, index) =>
          isAuthenticated() ? (
            <Route key={index} path={route.path} element={<route.element />} />
          ) : (
            <Route
              key={index}
              path={route.path}
              element={<Navigate to="/login" />}
            />
          )
        )}
        {/* <Route path="*" element={<NoRouteMatch />} /> */}
      </Routes>
    </>
  );
};

export default ProtectedRoute

import './App.scss';
// import "./Styles/bootstrap5CDN.css";
import { ApolloProvider } from '@apollo/client';
import AppRoutes from './Routes/AppRoutes';
import AuthProvider from './Context/authContext';
import ThemeProvider from './Context/themeContext';
import ConfigProvider from './Context/configurations';
import LanguageProvider from './Context/languageContext';
import useApolloClientConfigs from './configs/appoloClientConfigs';
// import NotificationsProvider from './Context/notificationsContext';


function App() {
  const { client } = useApolloClientConfigs();

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <LanguageProvider>
          <ConfigProvider>
            <ThemeProvider>
              <AppRoutes />
            </ThemeProvider>
          </ConfigProvider>
        </LanguageProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App

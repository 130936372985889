import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import loader from '../Assets/loader.gif';
import { useAuthContext } from '../Context/authContext';

//=================================================================================
//=================================================================================
//
//                                    ROUTES IMPORTS
//
//=================================================================================
//=================================================================================
const PurchaseOrder = lazy(() =>
  import('../Pages/Purchases/PurchaseOrder/PurchaseOrder'),
);
const Expenditures = lazy(() => import('../Pages/Finance/Expenditures/Index'));
const ExpendituresDetails = lazy(() =>
  import('../Pages/Finance/Expenditures/ExpendituresDetails'),
);
const SupplierIndex = lazy(() => import('../Pages/Purchases/Suppliers/Index'));
const SupplierDetails = lazy(() =>
  import('../Pages/Purchases/Suppliers/SupplierDetails'),
);
const SupplierInvoiceDetails = lazy(() =>
  import('../Pages/Purchases/Suppliers/SupplierInvoiceDetails'),
);
// const AdminPurchaseOrder = lazy(() =>
//   import('../Pages/AdminPurchases/PurchaseOrder/PurchaseOrder'),
// );
// const AdminExpenditures = lazy(() =>
//   import('../Pages/AdminPurchases/Expenditures/Index'),
// );
// const AdminExpendituresDetails = lazy(() =>
//   import('../Pages/AdminPurchases/Expenditures/ExpendituresDetails'),
// );
// const AdminSupplierIndex = lazy(() =>
//   import('../Pages/AdminPurchases/Suppliers/Index'),
// );
// const AdminSupplierDetails = lazy(() =>
//   import('../Pages/AdminPurchases/Suppliers/SupplierDetails'),
// );
// const AdminSupplierInvoiceDetails = lazy(() =>
//   import('../Pages/AdminPurchases/Suppliers/SupplierInvoiceDetails'),
// );
const SalesCustomerComponent = lazy(() => import('../Pages/Sales/Customer'));
const SalesCustomerDetails = lazy(() =>
  import('../Pages/Sales/Customer/Details'),
);
const CreateSalesInvoiceComponent = lazy(() =>
  import('../Pages/Sales/SalesInvoice/CreateInvoice'),
);
const DetailSaleInvoiceComponent = lazy(() =>
  import('../Pages/Sales/SalesInvoice/InvoiceDetail'),
);

const AdminCreateSalesInvoiceComponent = lazy(() =>
  import('../Pages/AdminSales/SalesInvoice/CreateInvoice'),
);
// const AdminDetailSaleInvoiceComponent = lazy(() =>
//   import('../Pages/AdminSales/SalesInvoice/InvoiceDetail'),
// );
const AdminDetailSaleInvoiceComponent = lazy(() =>
  import('../Pages/Sales/SalesInvoice/InvoiceDetail'),
);
const EmployeeDetails = lazy(() =>
  import('../Pages/Employees/EmployeeDetails'),
);
const StaffIndex = lazy(() => import('../Pages/Employees/Staff/Index'));
const DriverIndex = lazy(() => import('../Pages/Employees/Driver/Index'));
// const DriverDetail = lazy(() =>
//   import('../Pages/Employees/Driver/DriverDetails'),
// );

const ItemDetail = lazy(() =>
  import('../Pages/Inventory/ItemsDirectory/ItemDetail'),
);
const Adjustment = lazy(() =>
  import('../Pages/Inventory/Adjustment/Adjustment'),
);
const CreateNewAdjustment = lazy(() =>
  import('../Pages/Inventory/Adjustment/CreateNewAdjustment'),
);
const AdjustmentDetails = lazy(() =>
  import('../Pages/Inventory/Adjustment/AdjustmentDetails'),
);
const Reservation = lazy(() =>
  import('../Pages/Inventory/Reservation/Reservation'),
);
const ItemsDirectory = lazy(() =>
  import('../Pages/Inventory/ItemsDirectory/ItemsDirectory'),
);

const PurchaseInvoice = lazy(() => import('../Pages/Purchases/Invoice/Index'));
const CreatePurchaseInvoice = lazy(() =>
  import('../Pages/Purchases/Invoice/CreateInvoice'),
);
const PurchaseInvoiceDetails = lazy(() =>
  import('../Pages/Purchases/Invoice/InvoiceDetails'),
);

const AdminPurchaseInvoice = lazy(() =>
  import('../Pages/AdminPurchases/Invoice/Index'),
);
const AdminCreatePurchaseInvoice = lazy(() =>
  import('../Pages/AdminPurchases/Invoice/CreateInvoice'),
);
const AdminPurchaseInvoiceDetails = lazy(() =>
  import('../Pages/AdminPurchases/Invoice/InvoiceDetails'),
);

const SalesInvoiceComponent = lazy(() =>
  import('../Pages/Sales/SalesInvoice/index'),
);
const SalesQuotationComponent = lazy(() =>
  import('../Pages/Sales/SalesQuotation/index'),
);
// const AdminSalesInvoiceComponent = lazy(() =>
//   import('../Pages/AdminSales/SalesInvoice/index'),
// );

const PurchaseQuotation = lazy(() =>
  import('../Pages/Purchases/PurchaseQuotation/Index'),
);
const CreateNewQuotation = lazy(() =>
  import('../Pages/Purchases/PurchaseQuotation/CreatePurchaseQuotation'),
);
const PurchaseQuotationDetails = lazy(() =>
  import('../Pages/Purchases/PurchaseQuotation/QuotationDetails'),
);
// const AdminPurchaseQuotation = lazy(() =>
//   import('../Pages/AdminPurchases/PurchaseQuotation/Index'),
// );
// const AdminCreateNewQuotation = lazy(() =>
//   import('../Pages/AdminPurchases/PurchaseQuotation/CreatePurchaseQuotation'),
// );
// const AdminPurchaseQuotationDetails = lazy(() =>
//   import('../Pages/AdminPurchases/PurchaseQuotation/QuotationDetails'),
// );
const SaleQuotationDetails = lazy(() =>
  import('../Pages/Sales/SalesQuotation/SaleQuotationDetails'),
);
const CreateSalesQuotation = lazy(() =>
  import('../Pages/Sales/SalesQuotation/CreateSalesQuotation'),
);

const CreateNewOrder = lazy(() =>
  import('../Pages/Purchases/PurchaseOrder/CreateNewOrder'),
);
// const AdminCreateNewOrder = lazy(() =>
//   import('../Pages/AdminPurchases/PurchaseOrder/CreateNewOrder'),
// );
const PrintBarCode = lazy(() =>
  import('../Pages/Inventory/ItemsDirectory/PrintBarCode'),
);
const PurchaseOrderDetails = lazy(() =>
  import('../Pages/Purchases/PurchaseOrder/PurchaseOrderDetails'),
);
// const AdminPurchaseOrderDetails = lazy(() =>
//   import('../Pages/AdminPurchases/PurchaseOrder/PurchaseOrderDetails'),
// );
const Accounts = lazy(() => import('../Pages/Accounts/Index'));
const PaymentTerminal = lazy(() =>
  import('../Pages/Settings/PaymentTerminal/PaymentTerminal'),
);
const AccountsDetails = lazy(() => import('../Pages/Accounts/AccountsDetails'));
const SalesDelivery = lazy(() => import('../Pages/Sales/SalesDelivery/Index'));

const Reports = lazy(() => import('../Pages/Reports/Index'));
const CustomersReport = lazy(() =>
  import('../Pages/Reports/Sales/CustomersReport'),
);
const SalesReport = lazy(() => import('../Pages/Reports/Sales/SalesReport'));
const SalesReturnsReport = lazy(() =>
  import('../Pages/Reports/Sales/SalesReturnsReport'),
);
const SalesProfitReport = lazy(() =>
  import('../Pages/Reports/Sales/SalesProfitReport'),
);
const SalesItemReport = lazy(() =>
  import('../Pages/Reports/Sales/SalesItemReport'),
);
const BalancesCustomerReports = lazy(() =>
  import('../Pages/Reports/Accounts/BalancesCustomerReports'),
);
const StatementsAccountsReport = lazy(() =>
  import('../Pages/Reports/Accounts/StatementsAccountsReport'),
);
const BalancesSupplierReport = lazy(() =>
  import('../Pages/Reports/Accounts/BalancesSupplierReport'),
);
const NarrationsReport = lazy(() =>
  import('../Pages/Reports/Accounts/NarrationsReport'),
);
const SuppliersReport = lazy(() =>
  import('../Pages/Reports/Purchases/SuppliersReport'),
);
const PurchaseReport = lazy(() =>
  import('../Pages/Reports/Purchases/PurchaseReport'),
);
const PurchaseReturnsReport = lazy(() =>
  import('../Pages/Reports/Purchases/PurchaseReturnsReport'),
);
const PurchaseItemReport = lazy(() =>
  import('../Pages/Reports/Purchases/PurchaseItemReport'),
);
const CustomerInvoicesReport = lazy(() =>
  import('../Pages/Reports/Invoice/CustomerInvoicesReport'),
);
const SupplierInvoicesReport = lazy(() =>
  import('../Pages/Reports/Invoice/SupplierInvoicesReport'),
);
const PaymentsReceivedReport = lazy(() =>
  import('../Pages/Reports/Payments/PaymentsReceivedReport'),
);
const PaymentsMadeReport = lazy(() =>
  import('../Pages/Reports/Payments/PaymentsMadeReport'),
);
const ItemsReport = lazy(() =>
  import('../Pages/Reports/Inventory/ItemsReport'),
);
const InventoryAdjustmentReport = lazy(() =>
  import('../Pages/Reports/Inventory/InventoryAdjustmentReport'),
);
const InventoryMostSellingItemReport = lazy(() =>
  import('../Pages/Reports/Inventory/MostSellingItems'),
);
const SalesVATReport = lazy(() =>
  import('../Pages/Reports/Vat/SalesVATReport'),
);
const PurchaseVATReport = lazy(() =>
  import('../Pages/Reports/Vat/PurchaseVATReport'),
);
const TotalVatReport = lazy(() =>
  import('../Pages/Reports/Vat/TotalVATReport'),
);
const SalesQuotationReport = lazy(() =>
  import('../Pages/Reports/Quotations/SalesQuotationReport'),
);
const PurchaseQuotationReport = lazy(() =>
  import('../Pages/Reports/Quotations/PurchaseQuotationReport'),
);
const NetProfit = lazy(() => import('../Pages/Reports/Profits/NetProfit'));
const GrossProfit = lazy(() => import('../Pages/Reports/Profits/GrossProfit'));
const ItemProfit = lazy(() => import('../Pages/Reports/Profits/ItemProfit'));

const CounterSellingReport = lazy(() =>
  import('../Pages/Reports/Counter/CounterSellingReport'),
);
const CounterReturnReport = lazy(() =>
  import('../Pages/Reports/Counter/CounterReturnReport'),
);
const CreditNoteReport = lazy(() =>
  import('../Pages/Reports/Notes/CreditNote'),
);
const DebitNoteReport = lazy(() => import('../Pages/Reports/Notes/DebitNotes'));

const Dashboard = lazy(() => import('../Pages/Dashboard/Index'));
// ################ Settings Routes ################
const UserAndRoles = lazy(() => import('../Pages/Settings/UserAndRoles/index'));
const Prefrences = lazy(() => import('../Pages/Settings/Prefrences/'));
const BusinessInfo = lazy(() => import('../Pages/Settings/BusinessInfo/'));
const ProjectDetails = lazy(() =>
  import('../Components/Sales/Customer/TabsComponents/Projects/ProjectDetails'),
);
const AdminProjectDetails = lazy(() =>
  import(
    '../Components/AdminSales/Customer/TabsComponents/Projects/ProjectDetails'
  ),
);
const Login = lazy(() => import('../Pages/Auth/Login'));
const BusinessInfoLogin = lazy(() => import('../Pages/Auth/BusinessInfo'));
const Splash = lazy(() => import('../Pages/Auth/Splash'));
const PurchaseInvoicePrint = lazy(() =>
  import('../Components/Purchases/Invoice/PurchaseInvoicePrint'),
);
const InvoiceTemplateOne = lazy(() =>
  import('../Components/PrintTemplates/PrintTemplateOne/TaxInvoiceTemplateOne'),
);
const InvoiceTemplateOneSimple = lazy(() =>
  import(
    '../Components/PrintTemplates/PrintTemplateOne/TaxInvoiceTemplateOneSimplie'
  ),
);
const InvoiceTemplateTwo = lazy(() =>
  import('../Components/PrintTemplates/PrintTemplateTwo/InvoiceTemplateTwo'),
);
const InvoiceTemplateTwoSimple = lazy(() =>
  import(
    '../Components/PrintTemplates/PrintTemplateTwo/InvoiceTemplateTwoSimple'
  ),
);
const InvoiceTemplateThree = lazy(() =>
  import(
    '../Components/PrintTemplates/PrintTemplateThree/InvoiceTemplateThree'
  ),
);
const InvoiceTemplateThreeSimple = lazy(() =>
  import(
    '../Components/PrintTemplates/PrintTemplateThree/InvoiceTemplateThreeSimple'
  ),
);
const ThermalPrint = lazy(() =>
  import(
    '../Components/PrintTemplates/ThermalPrintTemplate/ThermalPrintTemplate'
  ),
);
const DeliveryVoucherTemplatePrint = lazy(() =>
  import('../Components/PrintTemplates/VoucherTemplates/ReturnVoucherTemplate'),
);
const ServiceDetails = lazy(() =>
  import('../Pages/Inventory/ItemsDirectory/ServiceDetails'),
);
const IndexTest = lazy(() => import('../Pages/Sales/SalesDelivery/IndexTest'));
const POS = lazy(() => import('../Pages/POS/POS/Index'));
const POSReturn = lazy(() => import('../Pages/POS/POS/PosRetrun'));
const Counters = lazy(() => import('../Pages/POS/Counters/Index'));
const ReportsTemplate = lazy(() =>
  import('../Components/PrintTemplates/ReportsTemplate/ReportsTemplate'),
);
const UserAndRoles2 = lazy(() =>
  import('../Pages/ManageAdmins/UserAndRoles/Index'),
);
const AddRole = lazy(() =>
  import('../Pages/ManageAdmins/UserAndRoles/AddRole'),
);
const AdminDashboard = lazy(() => import('../Pages/AdminDashboard/Index'));
const BranchesComponent = lazy(() => import('../Pages/Branches'));
const BranchDetailComponent = lazy(() =>
  import('../Pages/Branches/BranchDetails'),
);
const AdminBusinessInfo = lazy(() =>
  import('../Pages/AdminBusinessInfo/index'),
);
const Test = lazy(() => import('../Pages/Test/Test'));

const CreateItem = lazy(() =>
  import('../Pages/Inventory/ItemsDirectory/CreateItem'),
);
const AddCustomer = lazy(() => import('../Pages/Sales/Customer/AddCustomer'));

const SuperAdminItemDirectory = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/ItemsDirectory'),
);
const SuperAdminItemDetail = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/ItemDetail'),
);
const SuperAdminServiceDetails = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/ServiceDetails'),
);
const SuperAdminAddItemForm = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/CreateItem'),
);
const SuperAdminTransferItemsForm = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/TransferInventoryItems'),
);
const TransferHistory = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/TransferHistory'),
);
const TransferDetails = lazy(() =>
  import('../Pages/SuperAdminInventory/ItemsDirectory/TransferDetails'),
);
const AdminAccounts = lazy(() => import('../Pages/AdminAccounts/Index'));
const AdminAccountsDetails = lazy(() =>
  import('../Pages/AdminAccounts/AccountsDetails'),
);
const ChartsOfAccounts = lazy(() =>
  import('../Pages/Accounts/ChartsOfAccounts/Index'),
);
const ChartAccountDetails = lazy(() =>
  import('../Pages/Accounts/ChartsOfAccounts/ChartAccountDetails'),
);
const CreditDebitNote = lazy(() =>
  import('../Pages/Sales/SalesInvoice/CreditDebitNote'),
);
const CreateCreditNote = lazy(() =>
  import('../Pages/Sales/SalesInvoice/CreateCreditNote'),
);
const CreateDebitNote = lazy(() =>
  import('../Pages/Sales/SalesInvoice/CreateDebitNote'),
);
const CreditNoteDetails = lazy(() =>
  import('../Pages/Sales/SalesInvoice/CreditNoteDetails'),
);
const DebitNoteDetails = lazy(() =>
  import('../Pages/Sales/SalesInvoice/DebitNoteDetails'),
);

const OrganizationalStructure = lazy(() =>
  import('../Pages/HRM/OrganizationalStructure/Index'),
);
const DesignationDetials = lazy(() =>
  import('./../Pages/HRM/OrganizationalStructure/DesignationDetails'),
);
const DepartmentDetail = lazy(() =>
  import('./../Pages/HRM/OrganizationalStructure/DepartmentDetails'),
);
const IncentiveDetails = lazy(() =>
  import('./../Pages/HRM/OrganizationalStructure/IncentiveDetails'),
);
const HrmEmployee = lazy(() => import('./../Pages/HRM/Employees/Index'));
const HrmCreateEmployee = lazy(() =>
  import('./../Pages/HRM/Employees/CreateEmployee'),
);
const HrmEmpDetails = lazy(() =>
  import('./../Pages/HRM/Employees/EmployeeDetails'),
);
const HrmAttendance = lazy(() => import('./../Pages/HRM/Attendance/Index'));
const HrmPayrolls = lazy(() => import('./../Pages/HRM/Payrolls/Index'));
const HrmDashboard = lazy(() => import('./../Pages/HRM/Dashboard/Index'));
const HrmRequests = lazy(() => import('./../Pages/HRM/Requests/Index'));
const TransferItemForm = lazy(() =>
  import('./../Pages/Inventory/ItemTransferHistory/TransferInventoryItems'),
);
const TransferItemIndex = lazy(() =>
  import('./../Pages/Inventory/ItemTransferHistory/TransferHistory'),
);
const TransferItemDetails = lazy(() =>
  import('./../Pages/Inventory/ItemTransferHistory/TransferDetails'),
);
const FinancialYear = lazy(() =>
  import('./../Pages/Finance/FinancialYear/AddFinancialYear'),
);
const OpeningBalance = lazy(() =>
  import('./../Pages/Finance/OpeningBalance/Index'),
);
const IncomeStatement = lazy(() =>
  import('./../Pages/Reports/FinancialStatements/IncomeStatement'),
);
const BalanceSheet = lazy(() =>
  import('./../Pages/Reports/FinancialStatements/BalanceSheet'),
);
const TrailBalance = lazy(() =>
  import('./../Pages/Reports/FinancialStatements/TrailBalance'),
);
const JournalEntries = lazy(() =>
  import('./../Pages/Accounts/JournalEntries/Index'),
);
const HrmReports = lazy(() => import('./../Pages/HRM/Reports/Index'));
const HrmSalaryReports = lazy(() =>
  import('./../Pages/HRM/Reports/HrmSalaryReport'),
);
const HrmLeaveReport = lazy(() =>
  import('./../Pages/HRM/Reports/HrmLeaveReport'),
);
const HrmAttendaceReport = lazy(() =>
  import('./../Pages/HRM/Reports/HrmAttendanceReport'),
);
const HrmAdvanceCashReport = lazy(() =>
  import('./../Pages/HRM/Reports/HrmAdvanceCashReport'),
);
const DeliveryIndex = lazy(() =>
  import('../Pages/Delivery/SalesDelivery/Index'),
);
const DeliveryDriverIndex = lazy(() =>
  import('../Pages/Delivery/Driver/Index'),
);
const DeliveryDriverDetail = lazy(() =>
  import('../Pages/Delivery/Driver/DriverDetails'),
);
const DeliveryDriverCreate = lazy(() =>
  import('../Pages/Delivery/Driver/CreateDriver'),
);
const TransferReport = lazy(() =>
  import('../Pages/Reports/InventoryTransfer/TransferReport'),
);
const CommissionIndex = lazy(() => import('../Pages/Sales/Commission/Index'));
const CommissionReport = lazy(() =>
  import('../Pages/Reports/Commission/CommissionReport'),
);
const CommissionDetails = lazy(() =>
  import('../Pages/Sales/Commission/Details'),
);
const CommissionForm = lazy(() =>
  import('../Pages/Sales/Commission/CommissionForm'),
);
const TransferRequest = lazy(() =>
  import('../Pages/Inventory/TransferRequest/Index'),
);
const CreateTransferRequest = lazy(() =>
  import('../Pages/Inventory/TransferRequest/CreateTransferRequest'),
);
const TransferRequestDetails = lazy(() =>
  import('../Pages/Inventory/TransferRequest/TransferRequestDetails'),
);
const TransferRequestStatus = lazy(() =>
  import('../Pages/Inventory/TransferRequest/TransferReqStatusUpdate'),
);
const CostCenter = lazy(() => import('../Pages/Accounts/CostCenters/Index'));
// const AddCustomer = lazy(() => import("../Pages/Sales/Customer/AddCustomer")); //example

//=================================================================================
//=================================================================================
//
//                                    APP COMPONENT
//
//=================================================================================
//=================================================================================
const AppRoutes = () => {
  const routes = [
    {
      path: '/delivery-test',
      element: IndexTest,
    },
    {
      path: '/purchases/supplier/:supplierId/invoice-details/:id',
      element: SupplierInvoiceDetails,
    },
    {
      path: '/purchases/suppliers',
      element: SupplierIndex,
    },
    {
      path: '/purchases/supplier/:id',
      element: SupplierDetails,
    },

    {
      path: '/finance/expenditures',
      element: Expenditures,
    },
    {
      path: '/finance/expenditures/details/:id',
      element: ExpendituresDetails,
    },
    {
      path: '/employees/:details/:id',
      element: EmployeeDetails,
    },
    {
      path: '/employees/:details/:id/:driverId',
      element: EmployeeDetails,
    },
    {
      path: '/employees/staff',
      element: StaffIndex,
    },
    {
      path: '/employees/driver',
      element: DriverIndex,
    },
    // {
    //   path: '/employees/driver/details/:id',
    //   element: DriverDetail,
    // },
    {
      path: '/sales/Customer',
      element: SalesCustomerComponent,
    },
    {
      path: '/sales/Customer/details/:id',
      element: SalesCustomerDetails,
    },
    {
      path: '/sales/invoice',
      element: SalesInvoiceComponent,
    },
    {
      path: '/sales/invoice/create-invoice/:type/:Name',
      element: CreateSalesInvoiceComponent,
    },
    {
      path: '/sales/invoice/create-invoice',
      element: CreateSalesInvoiceComponent,
    },
    {
      path: '/sales/invoice/detail/:id',
      element: DetailSaleInvoiceComponent,
    },
    {
      path: '/inventory',
      element: ItemsDirectory,
    },
    {
      path: '/inventory/:id',
      element: ItemDetail,
    },
    {
      path: '/services/:id',
      element: ServiceDetails,
    },
    {
      path: '/inventory/print-barcode',
      element: PrintBarCode,
    },
    {
      path: '/adjustment',
      element: Adjustment,
    },
    {
      path: '/adjustment/create-new-adjustment',
      element: CreateNewAdjustment,
    },
    {
      path: '/adjustment/details/:id',
      element: AdjustmentDetails,
    },
    {
      path: '/inventory/reservation',
      element: Reservation,
    },
    {
      path: '/purchases/purchase-order',
      element: PurchaseOrder,
    },
    {
      path: '/purchases/purchase-order/create-new-order',
      element: CreateNewOrder,
    },
    {
      path: '/purchases/purchase-order/:id',
      element: PurchaseOrderDetails,
    },
    {
      path: '/purchases/invoice',
      element: PurchaseInvoice,
    },
    {
      path: '/purchases/invoice/create',
      element: CreatePurchaseInvoice,
    },
    {
      path: '/purchases/invoice/:id',
      element: PurchaseInvoiceDetails,
    },
    {
      path: '/sales-invoice',
      element: SalesInvoiceComponent,
    },
    {
      path: '/sale-quotation',
      element: SalesQuotationComponent,
    },
    {
      path: '/purchase-quotation',
      element: PurchaseQuotation,
    },
    {
      path: '/purchase-quotation/create-new',
      element: CreateNewQuotation,
    },
    {
      path: '/purchase-quotation/:id',
      element: PurchaseQuotationDetails,
    },
    {
      path: '/sale-quotation/:id',
      element: SaleQuotationDetails,
    },
    {
      path: '/sale-quotation/create-new',
      element: CreateSalesQuotation,
    },
    {
      path: '/accounts',
      element: Accounts,
    },
    {
      path: '/accounts/details/:id',
      element: AccountsDetails,
    },
    {
      path: '/sales/delivery',
      element: SalesDelivery,
    },
    {
      path: '/settings/user-and-roles',
      element: UserAndRoles,
    },
    {
      path: '/settings/payment-terminal',
      element: PaymentTerminal,
    },
    {
      path: '/settings/Preferences',
      element: Prefrences,
    },
    {
      path: '/settings/business-info',
      element: BusinessInfo,
    },
    {
      path: '/dashboard',
      element: Dashboard,
    },
    {
      path: '/sales/Customer/details/project/:id',
      element: ProjectDetails,
    },
    {
      path: '/reports',
      element: Reports,
    },
    {
      path: '/reports/sales/customers',
      element: CustomersReport,
    },
    {
      path: '/reports/sales/sales',
      element: SalesReport,
    },
    {
      path: '/reports/sales/returns',
      element: SalesReturnsReport,
    },
    {
      path: '/reports/sales/profit',
      element: SalesProfitReport,
    },
    {
      path: '/reports/sales/item',
      element: SalesItemReport,
    },
    {
      path: '/reports/accounts/balances-customer',
      element: BalancesCustomerReports,
    },
    {
      path: '/reports/accounts/balance-supplier',
      element: BalancesSupplierReport,
    },
    {
      path: '/reports/accounts/statements-accounts',
      element: StatementsAccountsReport,
    },
    {
      path: '/reports/accounts/narrations',
      element: NarrationsReport,
    },
    {
      path: '/reports/purchases/suppliers',
      element: SuppliersReport,
    },
    {
      path: '/reports/purchases/purchase',
      element: PurchaseReport,
    },
    {
      path: '/reports/purchases/return',
      element: PurchaseReturnsReport,
    },
    {
      path: '/reports/purchases/item',
      element: PurchaseItemReport,
    },
    {
      path: '/reports/invoice/customer',
      element: CustomerInvoicesReport,
    },
    {
      path: '/reports/invoice/supplier',
      element: SupplierInvoicesReport,
    },
    {
      path: '/reports/payments/received',
      element: PaymentsReceivedReport,
    },
    {
      path: '/reports/payments/made',
      element: PaymentsMadeReport,
    },
    {
      path: '/reports/inventory/items',
      element: ItemsReport,
    },
    {
      path: '/reports/inventory/adjustment',
      element: InventoryAdjustmentReport,
    },
    {
      path: '/reports/inventory/InventoryMostSellingItemReport',
      element: InventoryMostSellingItemReport,
    },
    {
      path: '/reports/vat/sales',
      element: SalesVATReport,
    },
    {
      path: '/reports/vat/purchase',
      element: PurchaseVATReport,
    },
    {
      path: '/reports/vat/totalvat',
      element: TotalVatReport,
    },
    {
      path: '/reports/quotations/sales',
      element: SalesQuotationReport,
    },
    {
      path: '/reports/quotations/purchase',
      element: PurchaseQuotationReport,
    },
    {
      path: '/reports/profits/netprofit',
      element: NetProfit,
    },
    {
      path: '/reports/profits/grossprofit',
      element: GrossProfit,
    },
    {
      path: '/reports/profits/itemProfit',
      element: ItemProfit,
    },
    {
      path: '/reports/counter/counterSellingReport',
      element: CounterSellingReport,
    },
    {
      path: '/reports/counter/counterReturnReport',
      element: CounterReturnReport,
    },
    {
      path: '/reports/notes/creditNote',
      element: CreditNoteReport,
    },
    {
      path: '/reports/notes/debitNote',
      element: DebitNoteReport,
    },
    {
      path: '/business-info',
      element: BusinessInfoLogin,
    },
    {
      path: '/splash',
      element: Splash,
    },
    {
      path: '/pi-print',
      element: PurchaseInvoicePrint,
    },
    {
      path: '/invoiceTemplateOne',
      element: InvoiceTemplateOne,
    },
    {
      path: '/invoiceTemplateOneSimple',
      element: InvoiceTemplateOneSimple,
    },
    {
      path: '/invoiceTemplateTwo',
      element: InvoiceTemplateTwo,
    },
    {
      path: '/invoiceTemplateTwoSimple',
      element: InvoiceTemplateTwoSimple,
    },
    {
      path: '/invoiceTemplateThree',
      element: InvoiceTemplateThree,
    },
    {
      path: '/invoiceTemplateThreeSimple',
      element: InvoiceTemplateThreeSimple,
    },
    {
      path: '/thermalPrint',
      element: ThermalPrint,
    },
    {
      path: '/DeliveryVoucherTemplatePrint',
      element: DeliveryVoucherTemplatePrint,
    },
    {
      path: '/pos',
      element: POS,
    },
    {
      path: '/pos/:id',
      element: POSReturn,
    },
    {
      path: '/counters',
      element: Counters,
    },
    {
      path: '/reports-template',
      element: ReportsTemplate,
    },
    {
      path: '/dashboard',
      element: Dashboard,
    },
    {
      path: '/sales/invoice/credit-debit-note/:id',
      element: CreditDebitNote,
    },
    {
      path: '/sales/invoice/create-credit-note/:id',
      element: CreateCreditNote,
    },
    {
      path: '/sales/invoice/create-debit-note/:id',
      element: CreateDebitNote,
    },
    {
      path: '/sales/invoice/credit-note-details/:invoiceId/:id',
      element: CreditNoteDetails,
    },
    {
      path: '/sales/invoice/debit-note-details/:invoiceId/:id',
      element: DebitNoteDetails,
    },
    {
      path: '/sales/Customer/customer-form',
      element: AddCustomer,
    },
    {
      path: '/item-form',
      element: CreateItem,
    },
    {
      path: '/reports',
      element: Reports,
    },
    { path: '/item-form', element: CreateItem },
    {
      path: '/sales/Customer/customer-form',
      element: AddCustomer,
    },
    {
      path: '/charts-of-accounts',
      element: ChartsOfAccounts,
    },
    {
      path: '/charts-of-accounts/details/:id',
      element: ChartAccountDetails,
    },
    {
      path: '/hrm/organizational-structure',
      element: OrganizationalStructure,
    },
    {
      path: '/hrm/organizational-structure/department/:id',
      element: DepartmentDetail,
    },
    {
      path: '/hrm/organizational-structure/designation-details/:id',
      element: DesignationDetials,
    },
    {
      path: '/hrm/organizational-structure/incentive/:id',
      element: IncentiveDetails,
    },
    {
      path: '/reports/financial-statement/income-statement',
      element: IncomeStatement,
    },
    {
      path: '/reports/financial-statement/balance-sheet',
      element: BalanceSheet,
    },
    {
      path: '/reports/financial-statement/trail-balance',
      element: TrailBalance,
    },
    { path: '/hrm/employee', element: HrmEmployee },
    { path: '/hrm/employee/:id', element: HrmEmpDetails },
    { path: '/hrm/employee/form', element: HrmCreateEmployee },
    { path: '/hrm/attendance', element: HrmAttendance },
    { path: '/hrm/payrolls', element: HrmPayrolls },
    { path: '/hrm/dashboard', element: HrmDashboard },
    { path: '/hrm/requests', element: HrmRequests },
    { path: '/hrm/reports', element: HrmReports },
    { path: '/transferItems', element: TransferItemForm },
    { path: '/transfer-history', element: TransferItemIndex },
    { path: '/transfer-history/:id', element: TransferItemDetails },
    { path: '/finance/financial-year', element: FinancialYear },
    { path: '/finance/opening-balance', element: OpeningBalance },
    { path: '/journal-entries', element: JournalEntries },
    { path: '/hrm/reports/salary-report', element: HrmSalaryReports },
    { path: '/hrm/reports/attendance-report', element: HrmAttendaceReport },
    { path: '/hrm/reports/leave-report', element: HrmLeaveReport },
    { path: '/hrm/reports/advance-cash-report', element: HrmAdvanceCashReport },
    { path: '/delivery/sale-delivery', element: DeliveryIndex },
    { path: '/delivery/driver', element: DeliveryDriverIndex },
    { path: '/delivery/driver/:id', element: DeliveryDriverDetail },
    { path: '/delivery/driver/create', element: DeliveryDriverCreate },
    { path: '/sales/commission', element: CommissionIndex },
    { path: '/sales/commission/:id', element: CommissionDetails },
    { path: '/sales/commission/form', element: CommissionForm },
    { path: '/reports/commission-report', element: CommissionReport },
    {
      path: '/reports/inventory-transfer/transfer-report',
      element: TransferReport,
    },
    { path: '/transfer-request', element: TransferRequest },
    { path: '/create-request', element: CreateTransferRequest },
    { path: '/transfer-request/:id', element: TransferRequestDetails },
    { path: '/accounts/cost-center', element: CostCenter },
    // {
    //   path: "/login",
    //   element: Login,
    // },
  ];

  //=================================================================================
  //=================================================================================
  //
  //                                    Super Admin Routes
  //
  //=================================================================================
  //=================================================================================
  const SuperAdminRoutes = [
    // {
    //   path: '/sales/Customer',
    //   element: AdminSalesCustomerComponent,
    // },
    // {
    //   path: '/sales/Customer/details/:id',
    //   element: AdminSalesCustomerDetails,
    // },
    {
      path: '/sales/invoice',
      element: SalesInvoiceComponent,
    },
    {
      path: '/sales/invoice/create-invoice/:type/:Name',
      element: AdminCreateSalesInvoiceComponent,
    },
    {
      path: '/sales/invoice/create-invoice',
      element: AdminCreateSalesInvoiceComponent,
    },
    {
      path: '/sales/invoice/detail/:id',
      element: AdminDetailSaleInvoiceComponent,
    },
    {
      path: '/sales/invoice',
      element: SalesInvoiceComponent,
    },
    // {
    //   path: '/sale-quotation',
    //   element: AdminSalesQuotationComponent,
    // },
    // {
    //   path: '/sale-quotation/:id',
    //   element: AdminSaleQuotationDetails,
    // },
    // {
    //   path: '/sale-quotation/create-new',
    //   element: AdminCreateSalesQuotation,
    // },
    // {
    //   path: '/sales/delivery',
    //   element: AdminSalesDelivery,
    // },
    {
      path: '/sales/Customer/details/project/:id',
      element: AdminProjectDetails,
    },
    // {
    //   path: '/sales/Customer/customer-form',
    //   element: AdminAddCustomer,
    // },
    // {
    //   path: '/purchases/supplier/:supplierId/invoice-details/:id',
    //   element: AdminSupplierInvoiceDetails,
    // },
    // {
    //   path: '/purchases/suppliers',
    //   element: AdminSupplierIndex,
    // },
    // {
    //   path: '/purchases/supplier/:id',
    //   element: AdminSupplierDetails,
    // },

    // {
    //   path: '/purchases/expenditures',
    //   element: AdminExpenditures,
    // },
    // {
    //   path: '/purchases/expenditures/details/:id',
    //   element: AdminExpendituresDetails,
    // },
    // {
    //   path: '/purchases/purchase-order',
    //   element: AdminPurchaseOrder,
    // },
    // {
    //   path: '/purchases/purchase-order/create-new-order',
    //   element: AdminCreateNewOrder,
    // },
    // {
    //   path: '/purchases/purchase-order/:id',
    //   element: AdminPurchaseOrderDetails,
    // },
    {
      path: '/purchases/invoice',
      element: AdminPurchaseInvoice,
    },
    {
      path: '/purchases/invoice/create',
      element: AdminCreatePurchaseInvoice,
    },
    {
      path: '/purchases/invoice/:id',
      element: AdminPurchaseInvoiceDetails,
    },
    // {
    //   path: '/purchase-quotation',
    //   element: AdminPurchaseQuotation,
    // },
    // {
    //   path: '/purchase-quotation/create-new',
    //   element: AdminCreateNewQuotation,
    // },
    // {
    //   path: '/purchase-quotation/:id',
    //   element: AdminPurchaseQuotationDetails,
    // },
    {
      path: '/dashboard',
      element: AdminDashboard,
    },
    {
      path: '/test',
      element: Test,
    },
    {
      path: '/branches',
      element: BranchesComponent,
    },
    {
      path: '/branches/:id',
      element: BranchDetailComponent,
    },
    {
      path: '/reports',
      element: Reports,
    },
    {
      path: '/manage-admins/user-and-roles',
      element: UserAndRoles2,
    },
    {
      path: '/manage-admins/user-and-roles/add-role',
      element: AddRole,
    },
    {
      path: '/reports/sales/customers',
      element: CustomersReport,
    },
    {
      path: '/reports/sales/sales',
      element: SalesReport,
    },
    {
      path: '/reports/sales/returns',
      element: SalesReturnsReport,
    },
    {
      path: '/reports/sales/profit',
      element: SalesProfitReport,
    },
    {
      path: '/reports/sales/item',
      element: SalesItemReport,
    },
    {
      path: '/reports/accounts/balances-customer',
      element: BalancesCustomerReports,
    },
    {
      path: '/reports/accounts/balance-supplier',
      element: BalancesSupplierReport,
    },
    {
      path: '/reports/accounts/statements-accounts',
      element: StatementsAccountsReport,
    },
    {
      path: '/reports/accounts/narrations',
      element: NarrationsReport,
    },
    {
      path: '/reports/purchases/suppliers',
      element: SuppliersReport,
    },
    {
      path: '/reports/purchases/purchase',
      element: PurchaseReport,
    },
    {
      path: '/reports/purchases/return',
      element: PurchaseReturnsReport,
    },
    {
      path: '/reports/purchases/item',
      element: PurchaseItemReport,
    },
    {
      path: '/reports/invoice/customer',
      element: CustomerInvoicesReport,
    },
    {
      path: '/reports/invoice/supplier',
      element: SupplierInvoicesReport,
    },
    {
      path: '/reports/payments/received',
      element: PaymentsReceivedReport,
    },
    {
      path: '/reports/payments/made',
      element: PaymentsMadeReport,
    },
    {
      path: '/reports/inventory/items',
      element: ItemsReport,
    },
    {
      path: '/reports/inventory/adjustment',
      element: InventoryAdjustmentReport,
    },
    {
      path: '/reports/inventory/InventoryMostSellingItemReport',
      element: InventoryMostSellingItemReport,
    },
    {
      path: '/reports/vat/sales',
      element: SalesVATReport,
    },
    {
      path: '/reports/vat/purchase',
      element: PurchaseVATReport,
    },
    {
      path: '/reports/vat/totalvat',
      element: TotalVatReport,
    },
    {
      path: '/reports/quotations/sales',
      element: SalesQuotationReport,
    },
    {
      path: '/reports/quotations/purchase',
      element: PurchaseQuotationReport,
    },
    {
      path: '/reports/profits/netprofit',
      element: NetProfit,
    },
    {
      path: '/reports/profits/grossprofit',
      element: GrossProfit,
    },
    {
      path: '/reports/profits/itemProfit',
      element: ItemProfit,
    },
    {
      path: '/reports/counter/counterSellingReport',
      element: CounterSellingReport,
    },
    {
      path: '/reports/counter/counterReturnReport',
      element: CounterReturnReport,
    },
    {
      path: '/admin-business-info',
      element: AdminBusinessInfo,
    },
    {
      path: '/inventory',
      element: SuperAdminItemDirectory,
    },
    {
      path: '/inventory/:id',
      element: SuperAdminItemDetail,
    },
    {
      path: '/services/:id',
      element: SuperAdminServiceDetails,
    },
    {
      path: '/sales/invoice/credit-debit-note/:id',
      element: CreditDebitNote,
    },
    {
      path: '/sales/invoice/create-credit-note/:id',
      element: CreateCreditNote,
    },
    {
      path: '/sales/invoice/create-debit-note/:id',
      element: CreateDebitNote,
    },
    {
      path: '/sales/invoice/credit-note-details/:invoiceId/:id',
      element: CreditNoteDetails,
    },
    {
      path: '/sales/invoice/debit-note-details/:invoiceId/:id',
      element: DebitNoteDetails,
    },
    {
      path: '/reports/notes/creditNote',
      element: CreditNoteReport,
    },
    {
      path: '/reports/notes/debitNote',
      element: DebitNoteReport,
    },
    { path: '/item-form', element: SuperAdminAddItemForm },
    { path: '/transferItems', element: SuperAdminTransferItemsForm },
    { path: '/transfer-history', element: TransferHistory },
    { path: '/transfer-history/:id', element: TransferDetails },
    { path: '/accounts', element: AdminAccounts },
    { path: '/accounts/details/:id', element: AdminAccountsDetails },
    { path: '/charts-of-accounts', element: ChartsOfAccounts },
    { path: '/charts-of-accounts/details/:id', element: ChartAccountDetails },
    { path: '/delivery/sale-delivery', element: DeliveryIndex },
    {
      path: '/reports/financial-statement/income-statement',
      element: IncomeStatement,
    },
    {
      path: '/reports/financial-statement/balance-sheet',
      element: BalanceSheet,
    },
    {
      path: '/reports/financial-statement/trail-balance',
      element: TrailBalance,
    },
    { path: '/journal-entries', element: JournalEntries },
    { path: '/transfer-request', element: TransferRequest },
    { path: '/create-request', element: CreateTransferRequest },
    {
      path: '/transfer-request/:id/:status',
      element: TransferRequestStatus,
    },
    { path: '/transfer-request/:id', element: TransferRequestDetails },
  ];

  const { getCurrentUserName } = useAuthContext();

  const Conditional_User_Routes = () => {
    if (getCurrentUserName() === 'superadmin') {
      return SuperAdminRoutes;
    }
    return routes;
  };

  // *** START *** LOGIC USED TO HANDLE BRANCH NAME IN URL
  // Don't change the order of code lines
  // const { setBranchName } = useZusAuth();
  // const branch = localStorage.getItem('basename'); // localStorage.setItem("basename", 'something') code is done in HandleLogin component
  // useEffect(() => {
  //   console.log('current username: ');
  //   if (
  //     window.location.pathname.split('/').length >= 3 &&
  //     getCurrentUserName() !== 'superadmin'
  //   ) {
  //     setBranchName(localStorage.branchName);
  //   }
  //   if (
  //     branch &&
  //     window.location.pathname.split('/').length >= 3 &&
  //     getCurrentUserName() !== 'superadmin'
  //   ) {
  //     setBasename(branch);
  //   }
  // }, [branch]);
  // *** END *** LOGIC USED TO HANDLE BRANCH NAME IN URL

  return (
    <Router>
      <Suspense
        fallback={
          <div className="main-loading-wrapper">
            <img src={loader} alt="" />
          </div>
        }
        s
      >
        {
          // public routes
        }
        <Routes>
          {/* <Route path="/:branch/login" element={<Login />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Routes>
        <ProtectedRoute routes={Conditional_User_Routes()} />
      </Suspense>
    </Router>
  );
};

export default AppRoutes;

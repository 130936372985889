import React, { createContext, useContext, useEffect, useState } from 'react';
import { getLocalStorage } from '../utils/sessionStorage';
import { updateTheme } from '../utils/updateTheme';

const themeContext = createContext();
export const useThemeContext = () => useContext(themeContext);

const ThemeProvider = (props) => {
  const [themeState, setThemeState] = useState();
  const [hideSideBar, setHideSideBar] = useState(false);
  const [hoverAnimation, setHoverAnimation] = useState(false);

  useEffect(() => {
    let theme = getTheme();
    // console.log("localstoreage theme", theme);
    if (!theme) {
      setTheme('light');
      setThemeState('light');
    } else {
      setTheme(theme);
      setThemeState(theme);
    }
    if (theme === 'dark') return document.body.classList.add('dark-mode');
  }, []);

  const setTheme = (themeValue) => {
    switch (themeValue) {
      case 'dark':
        updateTheme('dark');
        document.body.classList.add('dark-mode');
        document.body.dataset.theme = 'dark';

        break;
      case 'light':
        updateTheme('light');
        document.body.classList.remove('dark-mode');
        document.body.dataset.theme = 'light';
        break;
      default:
        updateTheme('light');
        document.body.classList.remove('dark-mode');
        break;
    }
    setThemeState(themeValue);
    // console.log("theme value state", themeValue);
  };

  const getTheme = () => {
    // console.log("getthene");
    return getLocalStorage('Theme', false);
  };

  return (
    <themeContext.Provider
      value={{
        setTheme,
        getTheme,
        themeState,
        hideSideBar,
        setHideSideBar,
        hoverAnimation,
        setHoverAnimation,
      }}
    >
      {props?.children}
    </themeContext.Provider>
  );
};

export default ThemeProvider;

/**
 * @param {string} name - The name of the sessionStorage item to set.
 * @param {object} data - data to be stored in sessionStorage
 * @param {boolean} jsonStringify - if true, data will be stringified before storing in sessionStorage
 */
export function setSessionStorage(name, data, jsonStringify = true) {
  sessionStorage.setItem(name, jsonStringify ? JSON.stringify(data) : data);
}
export function setLocalStorage(name, data, jsonStringify = true) {
  localStorage.setItem(name, jsonStringify ? JSON.stringify(data) : data);
}

/**
 *
 * @param {name} name - The name of the sessionStorage item to get.
 * @param {boolean} jsonParse - if true, data will be parsed from sessionStorage before returning
 * @returns {object} - data stored in sessionStorage
 */
export function getSessionStorage(name, jsonParse = true) {
  return jsonParse
    ? JSON.parse(sessionStorage.getItem(name))
    : sessionStorage.getItem(name);
}
export function getLocalStorage(name, jsonParse = true) {
  return jsonParse
    ? JSON.parse(localStorage.getItem(name))
    : localStorage.getItem(name);
}

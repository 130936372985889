import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
  split,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useTranslation } from 'react-i18next';
import { onError } from '@apollo/client/link/error';
import jwt_decode from 'jwt-decode';
import { message } from 'antd';
import { useMemo } from 'react';
import { getLocalStorage } from '../utils/browserStorage';
import { useZusAuth } from '../Store/store';

const getToken = () => {
  const user = getLocalStorage('user');
  const token = user?.token;
  return token;
};
const HOSTURL =
  window.location.host.includes('jmmtest.xyz') ||
  window.location.host.includes('localhost')
    ? 'https://graphqlv23.jmmtest.xyz/graphql'
    : 'https://graphql.hysabat.com/graphql/';

// ? 'https://graphqlv23.jmmtest.xyz/graphql'
const WSSURL =
  window.location.host.includes('jmmtest.xyz') ||
  window.location.host.includes('localhost')
    ? 'wss://graphqlv23.jmmtest.xyz/graphql'
    : 'https://graphql.hysabat.com/graphql/';

// no need to apply extra checks for development environment to check for tenant
// if the domain is not hysabat.com it will consider the environment as development

const TENANT = 'hysabat';
// const TENANT = window.location.host.includes('hysabat.com')
//   ? window.location.hostname.split('.')[0]
//   : 'hysabat';

const httpLink = createHttpLink({
  uri: HOSTURL,
});

// console.log("getToken ", getToken());

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value

// const errorLink = onError(({ graphqlErrors, networkError }) => {
//   if (graphqlErrors) {
//     graphqlErrors.map(({ message, location, path }) => {
//       return alert(`Graphql error ${message}`);
//     });
//   }
// });

// const link = from([
//   errorLink,
//   new HttpLink({
//     uri: "https://graphql.jmmtest.xyz/graphql/",
//   }),
// ]);

// ? ________________________________________________________
// ? Check the Response of Every Query Request To The Server
// ? ________________________________________________________

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  //         setTimeout(() => {
  //         }, 2000);

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      return;
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (getToken()) {
    // console.log(getToken(), 'is the token');
    const decodeData = jwt_decode(getToken());
    const date = new Date(decodeData.exp * 1000);
    // console.log(
    //   date,
    //   Date(0),
    //   Date.now(),
    //   Date.now() >= date,
    //   'is the only date and time',
    // );
    if (Date.now() >= date) {
      message.error('Token is Expired Login Again');
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      // logout();
    }
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem("token");
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;
  const token = user?.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',

      // _tenant_: window.location.hostname.split(".")[0] // Multitenancy
      tenant: TENANT, // temporary for localhost
    },
  };
});

const useApolloClientConfigs = () => {
  const { i18n } = useTranslation();
  const { branchName } = useZusAuth();

  const wsLink = new GraphQLWsLink(
    createClient({
      url: WSSURL,
      connectionParams: {
        tenant: TENANT,
        authorization: getToken() && `Bearer ${getToken()}`,
      },
    }),
  );

  const uploadLink = createUploadLink({
    uri: HOSTURL,
    headers: {
      tenant: TENANT,
      authorization: getToken() ? `Bearer ${getToken()}` : '',
      'Accept-Language': i18n.language === 'ar' ? 'ar-SA' : 'en-US',
      branchName: window.localStorage.branchName ?? '',
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    uploadLink,
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({}),
        link: from([errorLink, splitLink]),
        // link: authLink.concat(splitLink),
      }),
    [i18n.language, branchName],
  );

  return { client };
};

export default useApolloClientConfigs;
